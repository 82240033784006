// @flow

import moment from 'dayjs';
import React, { useState } from 'react';
import Cards from 'react-credit-cards';
import MaskedInput from 'react-maskedinput';

// $FlowFixMe
import 'react-credit-cards/es/styles-compiled.css';

import { useFormik } from 'formik';

import validationSchema from './validation-schema';

import Button from '../Button';
import MonthYearPicker from '../DatePicker';
import styles from './card.module.scss';

type InputProps = {
  onSubmit: (values: any) => void
};

//   placeholder: string,
//   type: string,
//   className?: string,
//   mask?: string,
//   placeholderChar?: string,
//   size?: number,
//   field: {
//     name: string,
//     value: string
//   },
//   onSubmit: (values: {}) => void,
//   form: {
//     errors: {},
//     touched: {}
//   }
// };

// eslint-disable-next-line react/prefer-exact-props
const CreditCard = (props: InputProps): any => {
  const { onSubmit } = props;
  const [isDatePickerVisible, setDatePickerVisible] = useState(false);

  const formik = useFormik({
    initialValues: {
      number: '',
      placeholder: '',
      name: '',
      cvc: '',
      expire: ''
    },
    enableReinitialize: true,
    validationSchema,
    validateOnMount: true,
    onSubmit: values => {
      if (onSubmit) {
        onSubmit(values);
      }
    }
  });

  const { errors, setFieldValue } = formik;

  const renderErrors = (controlObject: any) => {
    const { name } = controlObject;

    if (errors && errors[name]) {
      return <div className={styles.errors}>{errors[name]}</div>;
    }

    return null;
  };

  const handleExpireChange = (data: any) => {
    const { year, month } = data;
    const monthString = month < 10 ? `0${month}` : month;
    const value = `${monthString}/${String(year).slice(2)}`;
    setFieldValue('expire', value);
    setDatePickerVisible(false);
  };

  const isValid = Object.keys(errors).length === 0;
  const yearCurrent = moment().format('YYYY');
  const maxYear = Number(yearCurrent) + 10;

  return (
    <div className={styles.container}>
      <form onSubmit={formik.handleSubmit}>
        <h2>Please fill your billing information</h2>

        <div className={styles.grid}>
          <div>
            <Cards {...formik.values} />
          </div>

          <div>
            <div>
              <MaskedInput
                name="number"
                className={styles.maskInputCard}
                mask="1111 1111 1111 1111"
                placeholderChar="•"
                onChange={formik.handleChange}
                value={formik.values.number}
              />
              {renderErrors({ name: 'number' })}
            </div>

            <div>
              <input
                name="name"
                className={styles.inputName}
                type="text"
                placeholder="Cardholder name"
                onChange={formik.handleChange}
                value={formik.values.name}
              />
              {renderErrors({ name: 'name' })}
            </div>

            <div className={styles.cvcWrapper}>
              <input
                name="cvc"
                type="text"
                className={styles.input}
                size={3}
                placeholder="CVV"
                onChange={formik.handleChange}
                value={formik.values.cvc}
              />
              {renderErrors({ name: 'cvc' })}
            </div>

            <div className={styles.expireDate}>
              <MonthYearPicker
                maxYear={maxYear}
                isVisible={isDatePickerVisible}
                onChange={values => {
                  handleExpireChange(values);
                }}
                onClickOutside={() => {
                  setDatePickerVisible(false);
                }}
              />

              <input
                name="expire"
                type="text"
                className={styles.input}
                size={5}
                placeholder="MM/YY"
                onChange={formik.handleChange}
                onFocus={() => {
                  setDatePickerVisible(true);
                }}
                value={formik.values.expire}
              />
              {renderErrors({ name: 'expire' })}
            </div>
          </div>
        </div>

        <center>
          <Button disabled={!isValid}>Bingo!</Button>
        </center>
      </form>
    </div>
  );
};

export default CreditCard;
