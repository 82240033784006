import Home from '../screens/Home';
import Expertise from '../screens/Expertise';
import Page404 from '../screens/Page404';
import Contacts from '../screens/Contacts';
import HowWeWork from '../screens/How-we-work';
import ExpertiseEVCharging from '../screens/Expertise-charging';
import ExpertiseEmobility from '../screens/Expertise-emobility';
import ExpertiseDashboards from '../screens/Expertise-dashboards';
import ReactComponents from '../screens/Expertise-components';
import POCScreen from '../screens/POC';
import AICScreen from '../screens/AI-development';
import RouteScreen from '../screens/Expertise-route-builder';

// ADMIN AREA

const Routes = [
  {
    path: '/:language?',
    element: Home,
    exact: true
  },
  {
    path: '/:language?/how-we-work',
    element: HowWeWork,
    exact: true
  },
  {
    path: '/:language?/ai-solutions',
    element: AICScreen,
    exact: true
  },
  {
    path: '/poc',
    element: POCScreen,
    exact: true
  },
  {
    path: '/:language?/react-components',
    element: ReactComponents,
    exact: true
  },
  {
    path: '/:language?/react-components/:id',
    element: ReactComponents,
    exact: true
  },
  {
    path: '/:language?/expertise',
    element: Expertise,
    exact: true
  },
  {
    path: '/contacts',
    element: Contacts,
    exact: true
  },
  {
    path: '/:language?/expertise/emobility',
    element: ExpertiseEmobility,
    exact: true
  },
  {
    path: '/:language?/expertise/route-builder',
    element: RouteScreen,
    exact: true
  },
  {
    path: '/:language?/expertise/react-dashboards',
    element: ExpertiseDashboards,
    exact: true
  },
  {
    path: '/:language?/expertise/ev-charging',
    element: ExpertiseEVCharging,
    exact: true
  },
  // {
  //   path: '/about/:section([a-z0-9-]+)?/',
  //   component: About,
  //   exact: true
  // },
  {
    exact: false,
    path: '*',
    element: Page404
  }
];

export default Routes;
