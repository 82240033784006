const translate = {
  _SEO_TITLE1: 'Найміце сусветнага класа распрацоўшчыкаў для вашага бізнесу',
  _SEO_TITLE2:
    'Мы прапануем паслугі па распрацоўцы React.js, дапамагаючы бізнесу і стартапам ствараць свае праекты мары. Мы заўсёды шукаем лепшыя рашэнні для бізнесу нашых кліентаў. Адно з такіх рашэнняў - выкарыстанне React.js для распрацоўкі онлайн-сэрвісаў і вэб-прыкладанняў.',
  _SEO_TITLE3:
    'Распрацоўка прыкладанняў на React, распрацоўка на Nodejs, прыкладанні на React, серверны рэндэрынг, SSR прыкладанні, SSR React прыкладанне',
  _HOME_LINK1: 'Экспертыза',
  _HOME_LINK2: 'Як мы працуем',
  _HOME_LINK3: 'AI рашэнні',
  _HOME_CONTACT: 'Звязацца з намі',
  _HOME_SECTION3: 'Наша экспертыза',

  EXP_DESCRIPTION:
    'Прафесійная распрацоўка на React - стварэнне хуткіх, рэагуючых і маштабаваных вэб-прыкладанняў. Сучасныя тэхналогіі, высокі ўзровень UX/UI і аптымізацыя для вашых бізнес-мэтаў.',
  EXT_T1: 'НАША ЭКСПЕРТЫЗА',
  EXT_TT:
    'Наша каманда можа забяспечыць шырокі спектр тэхналагічнай экспертызы ў галіне мабільных, вэб, настольных і карпаратыўных праграмных рашэнняў ва ўсіх асноўных асяроддзях распрацоўкі.',

  WANT_BUTTON: 'Хочаце даведацца больш?',
  EXP_TITLE1: 'Рашэнні для электрамабільнасці',
  EXP_TEXT1:
    'Эфектыўныя рашэнні для абодвух бакоў індустрыі электрамабільнасці - аператараў EMSP і CPO. Гарантаваны бесперашкодны вопыт праз рэалізацыю адкрытых стандартаў, распрацоўку прыкладанняў і платформаў, кансультацыі па дамене і многае іншае.',

  EXP_TITLE2: 'Любое тып прыкладанняў на React',
  EXP_TEXT2:
    'Уявіце, што вам трэба распрацаваць вэб-прыкладанне з нуля для маніторынгу і аналізу сырых дадзеных. Напрыклад, вы валодаеце сярэднім інтэрнэт-крамай. Вас не задавальняюць існуючыя рашэнні для аналітыкі дадзеных, і вы хочаце самастойна кіраваць тым, якія кампаненты будуць у вашай панэлі адміністратара. Таксама вельмі важна, каб вы маглі працаваць з сырымі дадзенымі і самастойна будаваць аналітычныя графікі.',

  EXP_TITLE3: 'Патокі дадзеных для праектаў IoT',
  EXP_TEXT3: `Інтэрнэт рэчаў, або IoT, гэта сістэма ўзаемазвязаных вылічальных прылад, механічных і лічбавых машын, аб'ектаў, жывёл або людзей, якія забяспечаны унікальнымі ідэнтыфікатарамі (UID) і здольнасцю перадаваць дадзеныя па сетцы без неабходнасці ўзаемадзеяння чалавека з чалавекам або чалавека з кампутарам.`,
  EXP_TEXT4: `Рэч у інтэрнэце рэчаў можа быць чалавекам з імплантатам манітора сэрца, сельскагаспадарчай жывёлай з біёчыпам, аўтамабілем з убудаванымі датчыкамі, якія папярэджваюць кіроўцу аб нізкім ціску ў шынах, або любым іншым натуральным або штучным аб'ектам, які можа быць прызначаны Інтэрнэт-пратакол (IP) адрас і здольны перадаваць дадзеныя па сетцы.`,
  EXP_TEXT5:
    'Усё часцей арганізацыі ў розных галінах выкарыстоўваюць IoT для больш эфектыўнай працы, лепшага разумення кліентаў для паляпшэння абслугоўвання кліентаў, паляпшэння прыняцця рашэнняў і павышэння каштоўнасці бізнесу.',
  EXP_TITLE6: 'Відэастрымінг',
  EXP_TEXT6:
    'Мы прапануем шырокі спектр паслуг па распрацоўцы прыкладанняў для відэастрымінгу, з вялікай камандай спецыялістаў, гатовых дасягнуць вынікаў. У залежнасці ад вашых патрэб, мы можам стварыць хутка працуючыя платформы для жывога стрымінгу або паслугі відэа па запыце. Гэтыя прыкладанні не толькі створаны для максімальнай зручнасці (як у карыстацкім досведзе, так і ў кіраванні адміністратарам), але і надзейныя і прафесійна спраўляюцца з нагрузкай вялікіх патокаў трафіку.',

  EXP_TITLE7: 'Планавальнік паездак для шматстопавай лагістыкі',
  EXP_TEXT7: `На пярэднім краі ўстойлівых інавацый мы распрацоўваем перадавыя лагістычныя рашэнні, адаптаваныя да унікальных патрэб камерцыйных электрамабіляў (EV). Наша перадавая сістэма планавання маршрутаў дазваляе бізнесу аптымізаваць маршруты, бесперашкодна інтэграваць графікі зарадкі і павышаць агульную эфектыўнасць працы для паркаў электрамабіляў.`,

  _HWORK1: 'Як мы працуем',
  _HWORK2: `Няма такой складанай ідэі, для якой нельга было б распрацаваць аптымальнае архітэктурнае рашэнне. Наша каманда вопытных інжынераў React прынясе вашаму праекту вялікі аб'ём інстытуцыйных ведаў пра React, заснаваных на нашым рэальным вопыце стварэння розных прыкладанняў, ад MVP да складаных прыкладанняў узроўню прадпрыемства.`,
  _HWORK3: 'МЫ ПРАПАНУЕМ',
  _HWORK31: 'самы сучасны стэк тэхналогій для Front-end і Backend',
  _HWORK32:
    'лепшыя практыкі, заснаваныя на нашым вопыце з многімі рэальнымі вытворчымі праектамі',
  _HWORK34: 'рашэнні, гатовыя да вытворчасці і CI/CD',
  _HWORK35: 'кваліфікаваныя архітэктары для React Native, React і Backend',
  _HWORK36: 'прафесійная зваротная сувязь па існуючым праекце',
  _HWORK37: 'падтрымка рэалізацыі праекта ад пачатку да канца',
  _HWORK4: 'Фокус на тэставанні',
  _HWORK5:
    'Ключавая асаблівасць Node.js - гэта высокая якасць кода. Што, у сваю чаргу, азначае, што ён стабільны, прадказальны і дасягае сваёй мэты. Паколькі Node.js можа быць як дынамічна тыпізаванай мовай, так і моцна тыпізаванай мовай, якасць падтрымліваецца на неабходным узроўні шляхам аўтаматызаванага тэставання. Экасістэма Node.js прадастаўляе некалькі сучасных фрэймворкаў для аўтаматызаванага тэставання. Яны былі адпаліраваны і правераны ў баявых умовах на працягу многіх гадоў самымі вядомымі распрацоўшчыкамі ў супольнасці',
  _HWORK6: 'Хочаце даведацца больш прыкладаў?',

  _MOBILITY_DESCRIPTION:
    'Тут вы можаце знайсці наш раздзел экспертызы ў галіне распрацоўкі праграмнага забеспячэння для электрамабільнасці',
  _MOBILITY_KEYWORDS:
    'рашэнні для электрамабільнасці на react, дэманстрацыя ocpp на react, сістэма кіравання ocpp на react, панэль кіравання ocpp, сістэма білінгу ocpp, кіраванне зарадкай ocpp',
  _MOBILITY_HEAD_H1: 'Рашэнні для электрамабільнасці',
  _MOBILITY_HEAD_TEXT:
    'Эфектыўныя рашэнні для абодвух бакоў індустрыі электрамабільнасці - аператараў EMSP і CPO. Гарантаваны бесперашкодны вопыт праз рэалізацыю адкрытых стандартаў, распрацоўку прыкладанняў і платформаў, кансультацыі па дамене і многае іншае.',
  _MOBILITY_BACK: 'Вярнуцца да раздзела экспертызы',
  _MOBILITY_TITLE1: 'Прадукты будучыні для наступнага пакалення',
  _MOBILITY_TEXT1:
    'Праз праграмнае забеспячэнне мы прапануем найбольш гнуткія праграмныя рашэнні для інфраструктуры EV. Кіруйце любым зарадным прыладам OCPP, зараджайце любы электрамабіль і прымайце ўсе асноўныя спосабы аплаты.',
  _MOBILITY_TEXT2:
    'Нашы інавацыйныя прадукты фарміруюць будучыню аўтамабільнага дызайну. Мы базуем складаныя аўтамабільныя дызайны на перспектыўных рашэннях. Як даўні і надзейны партнёр аўтамабільнай прамысловасці, voestalpine вырабляе інавацыйныя прадукты ад дызайну да прататыпа і ад інструментальнай вытворчасці да серыйнай вытворчасці. Кожны кампанент, няхай гэта будзе асобная частка або складаны модуль, адпавядае неабходным патрабаванням бяспекі і зніжэння вагі. Такім чынам, кожны інавацыйны кампанент робіць унёсак у светлую будучыню.',
  _MOBILITY_TITLE2: 'Давайце пачнем',
  _MOBILITY_TEXT3:
    'Зарадка EV не павінна быць складанай. Мы можам забяспечыць ваша комплекснае рашэнне для інфраструктуры EV з мноствам налад і гнуткімі цэнамі для вашых кліентаў.',
  _MOBILITY_TITLE4: 'Кіраванне зараднымі прыладамі EV',
  _MOBILITY_TEXT4:
    'Падключайце і кіруйце любым зарадным прыладам OCPP. Вы можаце ствараць уласныя праграмныя планы, пачынаючы з $0. Бясплатныя гадзіны, зніжкі на розныя часовыя інтэрвалы дазваляюць дадаваць гейміфікацыю і маркетынгавыя функцыі да вашых станцый (падтрымка пратаколаў OCCP і найбольш вядомых вытворцаў зарадных прылад EV).',
  _MOBILITY_TITLE5: 'Інтэграцыі кіравання EV з CRM',
  _MOBILITY_TEXT5:
    'Мы можам лёгка інтэграваць нашы сістэмы кіравання зараднымі прыладамі з вашай сістэмай CRM, сэрвісамі апавяшчэнняў і рэалізаваць любую складанасць інтэграцыі з праграмным забеспячэннем трэціх бакоў.',
  _MOBILITY_MORE_BUTTON: 'Больш пра зарадку EV',
  _MOBILITY_TITLE6: 'Акаўнт кіроўцы EV',
  _MOBILITY_TEXT6:
    'Ніякіх членскіх узносаў або дэпазітаў у кашальку не патрабуецца, ні цяпер, ні калі-небудзь.',

  _CHARGING_TITLE: 'Праграмнае забеспячэнне для зарадкі EV (OCPP)',
  _CHARGING_DESCRIPTION:
    'Максімізуйце прыбытковасць вашай сеткі зарадных станцый і адчуйце наша сучаснае праграмнае забеспячэнне для зарадных станцый EV для аператараў і пастаўшчыкоў паслуг.',
  _CHARGING_TITLE1: 'Праграмнае забеспячэнне для індустрыі зарадкі EV',
  _CHARGING_TEXT1:
    'Выкарыстоўвайце наша праграмнае забеспячэнне для зарадкі EV з белай маркай, каб лёгка кіраваць вашай інфраструктурай зарадкі EV. Усе зарадныя пункты кіруюцца на шматузроўневым вэб-партале з падзелам роляў і адказнасцей – вы можаце вырашыць, хто можа атрымаць доступ да сістэмы. Мы прапануем як аўтсорсінгавыя, так і цалкам самакіраваныя пакеты нашага праграмнага забеспячэння для кіравання зарадкай EV, якія адпавядаюць вашым бізнес-патрэбам.',
  _CHARGING_TITLE2: 'Кіраванне зарадкай стала прасцей',
  _CHARGING_TEXT2:
    'Праграмнае забеспячэнне для кіравання зарадкай дазваляе адсочваць, кіраваць і аптымізаваць зарадку электрамабіляў і атрымліваць дадатковы даход. Ад доступу да пашыраных аналітычных дадзеных праз наш бізнес-партал да кіравання зарадкай у дарозе праз нашу праграму Charge, пераход на электрычнасць ніколі не быў прасцей.',
  _CHARGING_TITLE3: 'Аптымальнае выкарыстанне сеткі з разумнай зарадкай EV',
  _CHARGING_TEXT3:
    'Калі шмат электрамабіляў будуць зараджацца адначасова на адной сетцы, ёсць верагоднасць, што энергетычная сетка перагрузіцца. З разумнай зарадкай вы можаце павялічыць колькасць электрамабіляў, якія могуць зараджацца адначасова, без дадатковых інвестыцый у сетку.',
  _CHARGING_TITLE4: 'Кіраванне зараднымі прыладамі EV',
  _CHARGING_TEXT4:
    'Падключайце і кіруйце любым зарадным прыладам OCPP. Вы можаце ствараць уласныя праграмныя планы, пачынаючы з $0. Бясплатныя гадзіны, зніжкі на розныя часовыя інтэрвалы дазваляюць дадаваць гейміфікацыю і маркетынгавыя функцыі да вашых станцый (падтрымка пратаколаў OCCP і найбольш вядомых вытворцаў зарадных прылад EV).',
  _CHARGING_TITLE5: 'Кіраванне інфраструктурай',
  _CHARGING_TEXT51:
    'Кіраванне зараднымі станцыямі, зараднымі прыладамі і раздымамі.',
  _CHARGING_TEXT52: 'Маніторынг сесій, станцый і зарадных прылад.',
  _CHARGING_TEXT53: 'Разумнае кіраванне энергіяй з балансаваннем нагрузкі.',
  _CHARGING_TEXT54: 'Вырашэнне праблем.',
  _CHARGING_TEXT55:
    'Прымяненне аддаленых каманд (перазагрузка, абнаўленне прашыўкі і г.д.).',
  _CHARGING_TEXT56: 'Панэль кіравання з рэальнымі дадзенымі і картамі Google.',
  _CHARGING_TEXT57:
    'Гнуткасць абсталявання праз пратакол Open Charge Point – OCPP.',
  _CHARGING_TITLE6: 'Рашэнне платформы API',
  _CHARGING_TEXT6:
    'Наладзьце свой вопыт зарадкі EV з дапамогай нашай інавацыйнай і адкрытай платформы праграмнага забеспячэння і паслуг для зарадкі. Наша платформа API дазваляе аператарам ствараць уласны карыстацкі вопыт праз магутны шлюз API, які дазваляе вам распрацоўваць уласную праграму для кіроўцаў і выкарыстоўваць партал кіравання для кіравання вашымі станцыямі. Калі вы хочаце інтэграваць вашу бягучую мабільную праграму, звязаць зарадку з узнагародамі або членствам, або мець іншыя паслугі eMobility, наша платформа API падтрымлівае вашыя патрэбы. Яна таксама ідэальна падыходзіць для інтэграцыі ў аперацыі з аўтапаркам, рашэнні для кіравання энергіяй і сістэмы тэлематыкі транспартных сродкаў.',

  _DASHBOARD_TITLE: 'Распрацоўка панэлі кіравання (React)',
  _DASHBOARD_DESCRIPTION:
    'Максімізуйце прыбытковасць вашай сеткі зарадных станцый і адчуйце нашу сучасную праграму для зарадных станцый для аператараў і пастаўшчыкоў паслуг.',
  _DASHBOARD_TITLE1: 'Паслугі распрацоўкі React',
  _DASHBOARD_TEXT1:
    'Хочаце стварыць дынамічнае і інтэрактыўнае аднастайнае прыкладанне? Не шукайце далей! Мы прапануем паслугі распрацоўкі ReactJS для ўсіх вашых патрэб у распрацоўцы. Прыкладанні ReactJS, якія мы ствараем, вельмі функцыянальныя і даюць адчуванне роднага прыкладання для вашых мабільных прыкладанняў. Не трэба казаць, што яны лёгкія, хуткія і адчувальныя.',
  _DASHBOARD_BACK: 'Вярнуцца ў раздзел экспертызы',
  _DASHBOARD_TITLE2:
    'Карпаратыўныя рашэнні. Максімальная прадукцыйнасць. Якасць кода.',
  _DASHBOARD_TEXT21:
    'У нас ёсць спецыяльная каманда распрацоўшчыкаў React, якія выкарыстоўваюць гнуткасць і дынамічнасць, прапанаваныя React JS, для распрацоўкі вэб-прыкладанняў, арыентаваных на спажыўцоў, з самым высокім узроўнем бяспекі. Нашы паслугі распрацоўкі React дапамагаюць у распрацоўцы вэб-прыкладанняў, заснаваных на дадзеных, з вялікай хуткасцю, прастатой і надзейнасцю.',
  _DASHBOARD_TEXT22: `React JS з'яўляецца асноўнай платформай для фронтэнда, на якой мы ствараем карыстацкія інтэрфейсы і кампаненты UI. Усе нашы каманды маюць шырокія веды і вопыт у распрацоўцы React і працавалі над дзясяткамі праектаў.`,
  _DASHBOARD_TEXT23: `Доступ толькі да вопытных распрацоўшчыкаў React.js з вопытам у буйнамаштабнай вэб-распрацоўцы. Стандарты якасці, вызначаныя больш чым 200 праектамі.`,
  _DASHBOARD_BUTTON: 'Пакажыце мне прыклады',
  _DASHBOARD_TITLE3: 'Пачніце заўтра',
  _DASHBOARD_TEXT3:
    'Вам не трэба ствараць вашу ўнутраную арганізацыю. Найміце нашу каманду распрацоўшчыкаў для праекта і замяніце нас унутраным персаналам пазней.',
  _DASHBOARD_TITLE4: 'Распрацоўка React.js з нуля',
  _DASHBOARD_TEXT4:
    'Вы можаце аддаць распрацоўку React нам, і мы створым праект з нуля для вас.',
  _DASHBOARD_TITLE5: 'Міграцыя на React',
  _DASHBOARD_TEXT5:
    'Нашы эксперты выканаюць якасную міграцыю на React з любой іншай фронтэндавай платформы.',
  _DASHBOARD_TITLE6: 'Прысвечаная каманда распрацоўшчыкаў React.js',
  _DASHBOARD_TEXT6:
    'Найміце прысвечаных распрацоўшчыкаў React.js, каб задаволіць вашыя патрэбы ў маштабе. Яны таксама дастаткова кваліфікаваныя, каб перапрацаваць дрэнна напісаны код іншай кампаніі па распрацоўцы React.js, каб адпавядаць галіновым стандартам.',
  _DASHBOARD_TITLE7: 'Распрацоўка прыкладанняў React Native',
  _DASHBOARD_TEXT7:
    'Адчуйце ўсю магутнасць лепшай кросплатформеннай платформы для распрацоўкі мабільных прыкладанняў.',

  _AI_TITLE:
    'Наступнае пакаленне вэб-распрацоўкі: хуткія і якасныя рашэнні з Node.js, React.js і AI',
  _AI_TEXT:
    'У сучасным лічбавым свеце вэб-распрацоўка стала асновай паспяховага бізнесу. Мы спецыялізуемся на стварэнні дынамічных, маштабаваных і надзейных вэб-рашэнняў, якія забяспечваюць высокую прадукцыйнасць і зручнасць выкарыстання. Нашы інструменты — Node.js, React.js і тэхналогіі штучнага інтэлекту (AI) — дазваляюць нам ствараць рашэнні, адаптаваныя да патрэб вашага бізнесу.',
  _AI_KEYWORDS:
    'вэб-распрацоўка, стварэнне лэндынгаў, распрацоўка на Node.js, распрацоўка на React.js, распрацоўка AI, вэб-прыкладанні, стварэнне сайтаў, комплексная распрацоўка, мадэрнізацыя вэб-прыкладанняў, інтэграцыя штучнага інтэлекту, інтэрактыўныя вэб-рашэнні',
  _AI_TITLE1: 'Наш падыход:',
  _AI_SECTION1: '1. Хуткасць і эфектыўнасць',
  _AI_TEXT1:
    'Мы выкарыстоўваем Node.js для распрацоўкі сервернай часткі, каб забяспечыць маланкавую апрацоўку дадзеных, і React.js для стварэння інтэрактыўных карыстацкіх інтэрфейсаў. Гэта спалучэнне дазваляе хутчэй завяршаць праекты з мінімальнымі выдаткамі.',
  _AI_SECTION2: '2. Якасць і надзейнасць',
  _AI_TEXT2:
    'Мы імкнемся да найвышэйшай якасці на кожным этапе распрацоўкі. Нашы эксперты ўважліва кантралююць кожны крок праекта, каб забяспечыць яго надзейнасць і стабільнасць.',
  _AI_SECTION3: '3. Інтэграцыя AI',
  _AI_TEXT3:
    'Мы выкарыстоўваем тэхналогіі штучнага інтэлекту для стварэння разумных і інавацыйных рашэнняў, якія аптымізуюць бізнес-працэсы і павышаюць эфектыўнасць. Дадайце інавацыі ў ваш праект! Мы інтэгруем магчымасці AI, такія як:',
  _AI_TEXT31: 'Аўтаматызацыя працэсаў',
  _AI_TEXT32: 'Персаналізаваны карыстацкі досвед',
  _AI_TEXT33: 'Аналіз вялікіх дадзеных для прыняцця рашэнняў',
  _AI_TITLE2: 'Нашы паслугі:',
  _AI_SECTION4: 'Стварэнне вэб-прыкладанняў з нуля',
  _AI_TEXT4:
    'Поўны цыкл распрацоўкі — ад ідэі да гатовага прадукту. Мы распрацоўваем магутныя вэб-прыкладанні, якія эфектыўна вырашаюць вашы бізнес-задачы.',
  _AI_SECTION5: 'Міграцыя на сучасныя платформы',
  _AI_TEXT5:
    'Міграцыя састарэлых прыкладанняў на сучасныя тэхналогіі, такія як Node.js і React.js, для паляпшэння прадукцыйнасці і інтэрфейсу.',
  _AI_SECTION6: 'Інтэграцыя AI у існуючыя сістэмы',
  _AI_TEXT6:
    'Мы дапамагаем укараняць машыннае навучанне, чат-боты і іншыя AI-рашэнні ў ваш праект.',
  _AI_TITLE7: 'Гатовыя пачаць?',
  _AI_TEXT7:
    'Звяжыцеся з намі сёння, каб абмеркаваць ваш праект і даведацца, як мы можам дапамагчы вашаму бізнесу расці з сучаснымі тэхналогіямі, такімі як Node.js, React.js і AI.',

  _ROUTE_TITLE:
    'Прасунуты планіроўшчык маршрутаў для электрамабіляў для шматстанцыйнай лагістыкі',
  _ROUTE_DESCRIPTION:
    'Адкрыйце для сябе інавацыйныя рашэнні для лагістыкі камерцыйных электрамабіляў. Наш планіроўшчык маршрутаў для электрамабіляў з шматлікімі прыпынкамі аптымізуе маршруты, інтэгруе зарадныя станцыі і забяспечвае бесперабойную працу аўтапарка.',
  _ROUTE_KEYWORDS:
    'лагістыка электрамабіляў, планіроўшчык маршрутаў для электрамабіляў, шматстанцыйная маршрутызацыя, аптымізацыя зарадкі электрамабіляў, кіраванне аўтапаркам, устойлівая лагістыка, навігацыя электрамабіляў, інтэграцыя зарадных станцый'
};

export default translate;
