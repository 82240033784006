import React, { memo } from 'react';
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';
import * as Scroll from 'react-scroll';
import { useSelector } from 'react-redux';

// import Button from '../../components/Button';
import Section from '../../components/Section';
import styles from './route.module.scss';

import routeBuilder from '../../assets/img/map-route.jpg';
import routeBuilderExample from '../../assets/img/expertise/route-map-example.jpg';

import metaValues from '../../../config';
import translation from '../../translate';
import dictionary from './translate.json';

const EMobilityScreen = () => {
  const { defaultTitle } = metaValues;
  const { language } = useSelector(({ common }: any) => common);

  return (
    <div>
      <Helmet>
        <title>
          {translation('title', language, dictionary)} - {defaultTitle}
        </title>
        <meta
          name="description"
          content={translation('description', language, dictionary)}
        />
        <meta
          property="keywords"
          content={translation('keywords', language, dictionary)}
        />
        <meta property="og:title" content={defaultTitle} />
        <meta property="og:image" content="/public/route-builder-image.png" />
      </Helmet>

      <div className={styles.background}>
        <div className={styles.content}>
          <div className={styles.title}>
            <h1>{translation('headTitle', language, dictionary)}</h1>
            <p>{translation('intro', language, dictionary)}</p>
          </div>
        </div>
      </div>

      <Section className={styles.content}>
        <Link
          to={`/${language}/expertise`}
          onClick={() => Scroll.animateScroll.scrollToTop({ duration: 100 })}
        >
          {translation('_MOBILITY_BACK', language)}
        </Link>

        <h1>{translation('title', language, dictionary)}</h1>
        <p>{translation('intro', language, dictionary)}</p>

        <img
          src={routeBuilder}
          className={styles.image}
          alt="EV Route builder"
        />

        <h3>{translation('title_1', language, dictionary)}</h3>
        <p>{translation('text_11', language, dictionary)}</p>
        <p>{translation('text_12', language, dictionary)}</p>

        <h3>{translation('title_2', language, dictionary)}</h3>
        <p>{translation('text_2', language, dictionary)}</p>

        <h3>{translation('title_3', language, dictionary)}</h3>
        <h4>{translation('text_31', language, dictionary)}</h4>
        <p>{translation('text_311', language, dictionary)}</p>
        <h4>{translation('text_32', language, dictionary)}</h4>
        <p>{translation('text_321', language, dictionary)}</p>
        <h4>{translation('text_33', language, dictionary)}</h4>
        <p>{translation('text_331', language, dictionary)}</p>
        <h4>{translation('text_34', language, dictionary)}</h4>
        <p>{translation('text_341', language, dictionary)}</p>

        <img
          src={routeBuilderExample}
          className={styles.image}
          alt="EV Route builder"
        />

        <h3>{translation('title_4', language, dictionary)}</h3>
        <p>{translation('text_41', language, dictionary)}</p>
        <p>{translation('text_42', language, dictionary)}</p>
        <p>
          <b>{translation('text_43', language, dictionary)}</b>
        </p>

        <ul>
          <li>{translation('text_431', language, dictionary)}</li>
          <li>{translation('text_432', language, dictionary)}</li>
          <li>{translation('text_433', language, dictionary)}</li>
          <li>{translation('text_434', language, dictionary)}</li>
          <li>{translation('text_435', language, dictionary)}</li>
        </ul>
      </Section>
    </div>
  );
};

export default memo(EMobilityScreen);
