const translate = {
  _SEO_TITLE1:
    'Engagez des développeurs de classe mondiale pour votre entreprise',
  _SEO_TITLE2:
    'Nous proposons des services de développement avec React.js, aidant les entreprises et les startups à réaliser leurs projets de rêve. Nous recherchons toujours les meilleures solutions pour les affaires de nos clients. Une de ces solutions consiste à utiliser React.js pour développer des services en ligne et des applications web.',
  _SEO_TITLE3:
    'Développement d’applications React, développement Node.js, applications React, rendu côté serveur, applications SSR, applications React SSR',
  _HOME_LINK1: 'Expertise',
  _HOME_LINK2: 'Notre méthode',
  _HOME_LINK3: 'AI Solutions',
  _HOME_CONTACT: 'Contactez-nous',
  _HOME_SECTION3: 'Notre expertise',

  EXP_DESCRIPTION:
    'Développement professionnel sur React : création d’applications web rapides, réactives et évolutives. Technologies modernes, UX/UI de haut niveau et optimisation pour vos objectifs commerciaux.',
  EXT_T1: 'NOTRE EXPERTISE',
  EXT_TT:
    'Notre équipe peut fournir une large gamme d’expertises en technologies logicielles pour mobile, web, bureau et entreprise sur tous les principaux environnements de développement.',

  WANT_BUTTON: 'Vous voulez en savoir plus ?',
  EXP_TITLE1: 'Solutions de mobilité électrique',
  EXP_TEXT1:
    'Des solutions efficaces pour les deux côtés de l’industrie de la mobilité électrique : les opérateurs EMSP et CPO. Nous garantissons une expérience sans faille grâce à la mise en œuvre de normes ouvertes, au développement d’applications et de plateformes, au conseil en domaine, et bien plus encore.',

  EXP_TITLE2: 'Tout type d’applications React',
  EXP_TEXT2:
    'Imaginez que vous devez développer une application web à partir de zéro pour surveiller et analyser des données brutes. Par exemple, vous possédez une boutique en ligne de taille moyenne. Vous n’êtes pas satisfait des solutions existantes pour l’analyse de données et souhaitez gérer vous-même les composants de votre panneau d’administration. Il est également très important pour vous de travailler avec des données brutes et de créer vous-même des graphiques analytiques.',

  EXP_TITLE3: 'Flux de données pour projets IoT',
  EXP_TEXT3:
    'L’internet des objets (IoT) est un système de dispositifs informatiques interconnectés, de machines mécaniques et numériques, d’objets, d’animaux ou de personnes dotés d’identifiants uniques (UID) et capables de transférer des données via un réseau sans nécessiter d’interaction humaine directe.',
  EXP_TEXT4:
    'Un objet dans l’internet des objets peut être une personne avec un implant de moniteur cardiaque, un animal avec une puce bioélectronique, une voiture équipée de capteurs qui alertent le conducteur lorsque la pression des pneus est basse, ou tout autre objet naturel ou fabriqué pouvant avoir une adresse IP et être capable de transférer des données.',
  EXP_TEXT5:
    'De plus en plus d’organisations dans divers secteurs utilisent l’IoT pour fonctionner plus efficacement, mieux comprendre leurs clients et offrir un service client amélioré, prendre de meilleures décisions et augmenter la valeur de l’entreprise.',

  EXP_TITLE6: 'Streaming vidéo',
  EXP_TEXT6: `Nous proposons une gamme de services de développement d'applications de streaming vidéo, avec une grande équipe de spécialistes prête à livrer des résultats. Selon vos besoins, nous pouvons concevoir des plateformes de streaming en direct rapides ou des services de vidéo à la demande. Non seulement ces applications sont conçues pour une commodité maximale (à la fois pour l'expérience utilisateur et la gestion administrative), mais elles sont également robustes et gèrent efficacement les flux de trafic importants.`,

  EXP_TITLE7: 'Planificateur de trajets pour une logistique multi-arrêts',
  EXP_TEXT7: `À la pointe de l'innovation durable, nous développons des solutions logistiques de pointe adaptées aux besoins spécifiques des véhicules électriques commerciaux. Notre système avancé de planification d'itinéraires permet aux entreprises d'optimiser les trajets, d'intégrer facilement les plannings de recharge et d'améliorer l'efficacité opérationnelle des flottes de véhicules électriques.`,

  _HWORK1: 'Notre méthode de travail',
  _HWORK2:
    "Aucune idée n'est trop complexe pour qu'une solution architecturale optimale ne puisse être développée. Notre équipe d'ingénieurs expérimentés en React apporte à votre projet une richesse de connaissances institutionnelles sur React, basées sur notre expérience réelle de création d'une variété d'applications, allant de MVP à des applications complexes de niveau entreprise.",
  _HWORK3: 'CE QUE NOUS OFFRONS',
  _HWORK31:
    'le stack technologique le plus moderne pour le Front-end et le Back-end',
  _HWORK32:
    'les meilleures pratiques basées sur notre expérience avec de nombreux projets en production',
  _HWORK34: 'des solutions prêtes pour la production et le CI/CD',
  _HWORK35: 'des architectes qualifiés pour React Native, React et Back-end',
  _HWORK36: 'des retours professionnels sur un projet existant',
  _HWORK37: 'un accompagnement dans la mise en œuvre du projet de bout en bout',
  _HWORK4: 'Focus sur les tests',
  _HWORK5:
    "La caractéristique clé de Node.js est la qualité élevée du code. Cela signifie qu'il est stable, prévisible et atteint son objectif. Node.js peut être utilisé comme un langage dynamiquement typé ou fortement typé, et la qualité est maintenue au niveau requis grâce à l'utilisation de tests automatisés. L'écosystème Node.js offre plusieurs frameworks de test automatisé à la pointe de la technologie, qui ont été affinés et testés dans des environnements réels pendant des années par les développeurs les plus renommés de la communauté.",
  _HWORK6: 'Vous voulez en savoir plus sur nos exemples ?',

  _MOBILITY_DESCRIPTION:
    'Vous trouverez ici notre section d’expertise en E-Mobilité dans le développement logiciel',
  _MOBILITY_KEYWORDS:
    'solutions e-mobilité react, démonstration react ocpp, système de gestion react ocpp, tableau de bord ocpp, système de facturation ocpp, gestion de charge ocpp',
  _MOBILITY_HEAD_H1: 'Solutions E-Mobilité',
  _MOBILITY_HEAD_TEXT:
    "Des solutions efficaces pour les deux côtés de l'industrie de l'e-mobilité - opérateurs EMSP et CPO. Une expérience fluide garantie grâce à la mise en œuvre de normes ouvertes, au développement d'applications et de plateformes, à la consultation de domaine, et bien plus encore.",
  _MOBILITY_BACK: 'Retour à la section expertise',
  _MOBILITY_TITLE1: 'Les produits du futur pour la prochaine génération',
  _MOBILITY_TEXT1:
    "Grâce à nos logiciels, nous apportons à l'industrie des solutions logicielles d'infrastructure EV les plus flexibles. Gérez n'importe quel chargeur OCPP, chargez n'importe quelle voiture électrique et acceptez tous les principaux moyens de paiement.",
  _MOBILITY_TEXT2:
    "Nos produits innovants façonnent l'avenir de la conception automobile. Nous basons des conceptions automobiles complexes sur des solutions avant-gardistes. En tant que partenaire de longue date et fiable de l'industrie automobile, voestalpine fabrique des produits innovants, du design au prototype, de la fabrication d'outils à la production en série. Chaque composant, qu'il s'agisse d'une pièce individuelle ou d'un module complexe, répond aux exigences nécessaires en matière de sécurité et de réduction de poids. De cette manière, chaque composant innovant contribue à un avenir meilleur.",
  _MOBILITY_TITLE2: 'Commençons',
  _MOBILITY_TEXT3:
    "La recharge EV n'a pas à être compliquée. Nous pouvons vous fournir une solution d'infrastructure EV de bout en bout avec de nombreux paramètres personnalisés et des prix flexibles pour vos clients.",
  _MOBILITY_TITLE4: 'Gestion des bornes de recharge EV',
  _MOBILITY_TEXT4:
    "Connectez et contrôlez n'importe quel chargeur EV OCPP. Vous pouvez créer vos propres plans logiciels à partir de 0 $. Les heures gratuites, les remises sur différentes plages horaires vous permettent d'ajouter des fonctionnalités de gamification et de marketing à vos stations (prise en charge des protocoles OCCP et des fabricants de bornes de recharge EV les plus connus).",
  _MOBILITY_TITLE5: 'Intégrations de gestion EV avec CRM',
  _MOBILITY_TEXT5:
    'Nous pouvons facilement intégrer nos systèmes de gestion pour chargeurs à votre système CRM, à des services de notification et implémenter des intégrations de toute complexité avec des logiciels tiers.',
  _MOBILITY_MORE_BUTTON: 'En savoir plus sur la recharge EV',
  _MOBILITY_TITLE6: 'Compte de conducteur EV',
  _MOBILITY_TEXT6:
    "Pas de frais d'adhésion ni de dépôt de portefeuille requis, maintenant ou jamais.",

  _CHARGING_TITLE: 'Logiciel de recharge EV (OCPP)',
  _CHARGING_DESCRIPTION:
    'Maximisez la rentabilité de votre réseau de recharge et découvrez notre logiciel de pointe pour stations de recharge EV, destiné aux opérateurs et aux prestataires de services.',
  _CHARGING_TITLE1: 'Logiciel pour l’industrie de la recharge EV',
  _CHARGING_TEXT1:
    'Utilisez notre logiciel de recharge EV en marque blanche pour gérer facilement votre infrastructure de recharge EV. Tous les points de recharge sont administrés sur un portail web multi-niveaux avec des rôles et des responsabilités distincts – vous décidez qui peut accéder au système. Nous proposons des packages de gestion de recharge EV externalisés ou entièrement autonomes, adaptés à vos besoins professionnels.',
  _CHARGING_TITLE2: 'Gestion de la recharge simplifiée',
  _CHARGING_TEXT2:
    "Le logiciel de gestion de la recharge permet de suivre, gérer et optimiser la recharge des véhicules électriques et de générer des revenus supplémentaires. De l'accès à des informations avancées via notre portail Business à la gestion de la recharge en déplacement via notre application Charge, passer à l'électrique n'a jamais été aussi simple.",
  _CHARGING_TITLE3:
    'Utilisation optimale du réseau avec la recharge intelligente EV',
  _CHARGING_TEXT3:
    "Si de nombreux véhicules électriques se rechargent en même temps sur le même réseau, il existe un risque de surcharge du réseau énergétique. Avec la recharge intelligente, vous pouvez augmenter le nombre de véhicules électriques pouvant être rechargés simultanément sans effectuer d'investissements supplémentaires dans le réseau.",
  _CHARGING_TITLE4: 'Gestion des bornes de recharge EV',
  _CHARGING_TEXT4:
    "Connectez et contrôlez n'importe quel chargeur EV OCPP. Vous pouvez créer vos propres plans logiciels à partir de 0 $. Les heures gratuites, les remises sur différentes plages horaires vous permettent d'ajouter des fonctionnalités de gamification et de marketing à vos stations (prise en charge des protocoles OCCP et des fabricants de bornes de recharge EV les plus connus).",
  _CHARGING_TITLE5: 'Gestion de l’infrastructure',
  _CHARGING_TEXT51:
    'Gérez les stations de recharge EV, les chargeurs et les connecteurs.',
  _CHARGING_TEXT52: 'Surveillez les sessions, les stations et les chargeurs.',
  _CHARGING_TEXT53:
    "Gestion intelligente de l'énergie avec équilibrage de charge.",
  _CHARGING_TEXT54: 'Résolution de problèmes.',
  _CHARGING_TEXT55:
    'Appliquez des commandes à distance (redémarrage, mise à jour du firmware, etc.).',
  _CHARGING_TEXT56:
    'Tableau de bord avec données en temps réel et Google Maps.',
  _CHARGING_TEXT57:
    'Flexibilité matérielle grâce au protocole OCPP (Open Charge Point Protocol).',
  _CHARGING_TITLE6: 'Solution de plateforme API',
  _CHARGING_TEXT6:
    "Personnalisez votre expérience de recharge EV grâce à notre plateforme de réseau de recharge ouverte et innovante composée de logiciels et de services. Notre plateforme API permet aux opérateurs de créer leur propre expérience utilisateur via une passerelle API puissante qui vous permet de développer votre propre application pour conducteurs et de tirer parti du portail de gestion pour gérer vos stations. Que vous souhaitiez intégrer votre application mobile actuelle, inclure la recharge dans des programmes de récompenses ou d'adhésion, ou disposer d'autres services e-Mobilité, notre plateforme API prend en charge vos besoins. Elle est également parfaite pour l'intégration dans les opérations de flotte, les solutions de gestion de l'énergie et les systèmes de télématique des véhicules.",

  _DASHBOARD_TITLE: 'Développement de tableaux de bord (React)',
  _DASHBOARD_DESCRIPTION:
    'Maximisez la rentabilité de votre réseau de recharge et découvrez notre logiciel de pointe pour les opérateurs et les prestataires de services de bornes de recharge pour VE.',
  _DASHBOARD_TITLE1: 'Services de développement React',
  _DASHBOARD_TEXT1:
    'Vous souhaitez créer une application à page unique dynamique et interactive ? Ne cherchez plus ! Nous proposons des services de développement ReactJS pour répondre à tous vos besoins. Les applications ReactJS que nous créons sont hautement fonctionnelles et offrent une sensation native à vos applications mobiles. Naturellement, elles sont légères, rapides et réactives.',
  _DASHBOARD_BACK: 'Retour à la section expertise',
  _DASHBOARD_TITLE2:
    "Solutions d'entreprise. Performances maximales. Qualité du code.",
  _DASHBOARD_TEXT21:
    'Nous avons une équipe dédiée de développeurs React qui exploitent la flexibilité et le dynamisme de React JS pour développer des applications web orientées utilisateur avec un haut niveau de sécurité. Nos services de développement React permettent de créer des applications web axées sur les données avec rapidité, simplicité et robustesse.',
  _DASHBOARD_TEXT22:
    'React JS est la principale plateforme frontale sur laquelle nous construisons des interfaces utilisateur et des composants UI. Toutes nos équipes possèdent une vaste connaissance et expérience en développement React et ont travaillé sur des dizaines de projets.',
  _DASHBOARD_TEXT23:
    'Accédez uniquement à des développeurs React.js expérimentés ayant un historique de réussite dans le développement web à grande échelle. Normes de qualité définies par plus de 200 projets.',
  _DASHBOARD_BUTTON: 'Montrez-moi des exemples',
  _DASHBOARD_TITLE3: 'Commencez demain',
  _DASHBOARD_TEXT3:
    "Vous n'avez pas besoin de construire votre organisation interne. Engagez notre équipe de développeurs pour le projet et remplacez-nous par votre personnel interne plus tard.",
  _DASHBOARD_TITLE4: 'Développement React.js à partir de zéro',
  _DASHBOARD_TEXT4:
    'Vous pouvez externaliser le développement React à notre équipe, et nous construirons un projet pour vous depuis le début.',
  _DASHBOARD_TITLE5: 'Migration vers React',
  _DASHBOARD_TEXT5:
    "Nos experts effectueront une migration de haute qualité vers React à partir de n'importe quel autre framework frontal.",
  _DASHBOARD_TITLE6: 'Équipe dédiée au développement React.js',
  _DASHBOARD_TEXT6:
    "Engagez des développeurs React.js dédiés adaptés à vos besoins à grande échelle. Ils sont également suffisamment compétents pour refactoriser du code mal écrit par une autre entreprise de développement React.js afin de répondre aux normes de l'industrie.",
  _DASHBOARD_TITLE7: "Développement d'applications React Native",
  _DASHBOARD_TEXT7:
    "Découvrez toute la puissance du principal framework de développement d'applications mobiles multiplateformes.",

  _AI_TITLE:
    "Développement web de nouvelle génération : Des solutions rapides et de qualité avec Node.js, React.js et l'IA",
  _AI_TEXT:
    "Dans le monde numérique d'aujourd'hui, le développement web est devenu la base d'un business réussi. Nous sommes spécialisés dans la création de solutions web dynamiques, évolutives et fiables qui garantissent des performances élevées et une grande facilité d'utilisation. Nos outils — Node.js, React.js et les technologies d'intelligence artificielle (IA) — nous permettent de créer des solutions parfaitement adaptées aux besoins de votre entreprise.",
  _AI_KEYWORDS:
    "développement web, création de landing pages, développement Node.js, développement React.js, développement IA, applications web, création de sites internet, développement clé en main, modernisation des applications web, intégration de l'intelligence artificielle, solutions web interactives",
  _AI_TITLE1: 'Notre approche :',
  _AI_SECTION1: '1. Vitesse et efficacité',
  _AI_TEXT1:
    'Nous utilisons Node.js pour le développement backend, garantissant un traitement des données ultra-rapide, et React.js pour concevoir des interfaces utilisateur interactives. Cette combinaison permet une livraison rapide des projets à des coûts minimaux.',
  _AI_SECTION2: '2. Qualité et fiabilité',
  _AI_TEXT2:
    'Nous visons une qualité optimale à chaque étape du développement. Nos experts surveillent attentivement chaque étape du projet pour garantir sa fiabilité et sa stabilité.',
  _AI_SECTION3: "3. Intégration de l'IA",
  _AI_TEXT3:
    "Nous utilisons les technologies de l'intelligence artificielle pour créer des solutions intelligentes et innovantes qui optimisent les processus commerciaux et augmentent l'efficacité de votre entreprise. Apportez de l'innovation à votre projet ! Nous intégrons des capacités d'IA telles que :",
  _AI_TEXT31: 'Automatisation des processus',
  _AI_TEXT32: "Personnalisation de l'expérience utilisateur",
  _AI_TEXT33: 'Analyse des données massives pour la prise de décision',
  _AI_TITLE2: 'Nos services :',
  _AI_SECTION4: "Création d'applications web à partir de zéro",
  _AI_TEXT4:
    "Un cycle complet de développement — de l'idée au produit final. Nous développons des applications web puissantes qui répondent efficacement aux défis de votre entreprise.",
  _AI_SECTION5: 'Migration vers des plateformes modernes',
  _AI_TEXT5:
    "Migration d'applications obsolètes vers des technologies modernes comme Node.js et React.js pour améliorer les performances et l'interface.",
  _AI_SECTION6: "Intégration de l'IA dans les systèmes existants",
  _AI_TEXT6:
    "Nous aidons à intégrer des solutions d'IA telles que l'apprentissage automatique, les chatbots et d'autres dans votre projet.",
  _AI_TITLE7: 'Prêt à commencer ?',
  _AI_TEXT7:
    "Contactez-nous dès aujourd'hui pour discuter de votre projet et découvrir comment nous pouvons aider votre entreprise à se développer grâce à des technologies modernes telles que Node.js, React.js et l'IA."
};

export default translate;
