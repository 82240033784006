import React from 'react';
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';
import * as Scroll from 'react-scroll';
import { useSelector } from 'react-redux';

// import Button from '../../components/Button';
import Section from '../../components/Section';
import Button from '../../components/Button';
import styles from './dashboard.module.scss';
import metaValues from '../../../config';
import reactImage from '../../assets/svg/reactjs.svg';
import reactIllustration from '../../assets/img/react-application.png';
import translation from '../../translate';

const ReactDashboards = () => {
  const { defaultTitle } = metaValues;
  const { language } = useSelector(({ common }: any) => common);

  return (
    <div className={styles.body}>
      <Helmet>
        <title>
          {translation('_DASHBOARD_TITLE', language)} - {defaultTitle}
        </title>
        <meta
          name="description"
          content={translation('_DASHBOARD_DESCRIPTION', language)}
        />
        <meta property="og:title" content={defaultTitle} />
        <meta property="og:image" content="/public/productivity.svg" />
      </Helmet>

      <div className={styles.background}>
        <div className={styles.context}>
          <div className={styles.title}>
            <h1>{translation('_DASHBOARD_TITLE1', language)}</h1>
            <p>{translation('_DASHBOARD_TEXT1', language)}</p>
          </div>
        </div>
      </div>

      <Section className={styles.content}>
        <Link to={`/${language}/expertise`}>
          {translation('_DASHBOARD_BACK', language)}
        </Link>

        <h1>{translation('_DASHBOARD_TITLE2', language)}</h1>

        <img
          src={reactIllustration}
          className={styles.reactImage}
          alt="React applications development"
        />

        <p>{translation('_DASHBOARD_TEXT21', language)}</p>
        <p>{translation('_DASHBOARD_TEXT22', language)}</p>
        <p>{translation('_DASHBOARD_TEXT23', language)}</p>

        <img
          src={reactImage}
          className={styles.reactImage}
          alt="React applications development"
        />

        <Link
          to={`/${language}/react-components`}
          onClick={() => Scroll.animateScroll.scrollToTop({ duration: 100 })}
        >
          <Button>{translation('_DASHBOARD_BUTTON', language)}</Button>
        </Link>

        <h3>{translation('_DASHBOARD_TITLE3', language)}</h3>
        <p>{translation('_DASHBOARD_TEXT3', language)}</p>

        <h3>{translation('_DASHBOARD_TITLE4', language)}</h3>
        <p>{translation('_DASHBOARD_TEXT4', language)}</p>

        <h3>{translation('_DASHBOARD_TITLE5', language)}</h3>
        <p>{translation('_DASHBOARD_TEXT5', language)}</p>

        <h3>{translation('_DASHBOARD_TITLE6', language)}</h3>
        <p>{translation('_DASHBOARD_TEXT6', language)}</p>

        <h3>{translation('_DASHBOARD_TITLE7', language)}</h3>
        <p>{translation('_DASHBOARD_TEXT7', language)}</p>
      </Section>
    </div>
  );
};

export default ReactDashboards;
