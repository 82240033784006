const translate = {
  _SEO_TITLE1: 'Stellen Sie erstklassige Entwickler für Ihr Unternehmen ein',
  _SEO_TITLE2:
    'Wir bieten React.js-Entwicklungsdienste an, um Unternehmen und Startups bei der Umsetzung ihrer Traumprojekte zu unterstützen. Wir sind stets auf der Suche nach den besten Lösungen für die Unternehmen unserer Kunden. Eine dieser Lösungen ist die Verwendung von React.js für die Entwicklung von Onlinediensten und Webanwendungen.',
  _SEO_TITLE3:
    'React-Anwendungsentwicklung, Nodejs-Entwicklung, React-Anwendungen, serverseitiges Rendering, SSR-Apps, SSR-React-App',
  _HOME_LINK1: 'Sachverstand',
  _HOME_LINK2: 'Wie wir arbeiten',
  _HOME_LINK3: 'AI Lösungen',
  _HOME_CONTACT: 'Kontakte',
  _HOME_SECTION3: 'Unsere Expertise',

  // -----------

  EXP_DESCRIPTION:
    'Professionelle React-Entwicklung — Erstellung schneller, reaktionsfähiger und skalierbarer Webanwendungen. Moderne Technologien, hochwertige UX/UI und Optimierung für Ihre Geschäftsziele.',
  EXT_T1: 'UNSER ERFAHRUNGSSCHATZ',
  EXT_TT:
    'Unser Team kann ein breites Spektrum an Fachwissen über Mobil-, Web-, Desktop- und Unternehmenssoftwaretechnologie in allen wichtigen Entwicklungsumgebungen bereitstellen.',

  WANT_BUTTON: 'Möchten Sie mehr wissen?',
  EXP_TITLE1: 'E-Mobilitätslösungen',
  EXP_TEXT1:
    'Effiziente Lösungen für beide Seiten der E-Mobilitätsbranche – EMSP- und CPO-Betreiber. Garantierte nahtlose Erfahrung durch Implementierung offener Standards, Entwicklung von Apps und Plattformen, Domänenberatung und vieles mehr.',

  EXP_TITLE2: 'Jede Art von React-Anwendungen',
  EXP_TEXT2:
    'Stellen Sie sich vor, Sie müssen eine Webanwendung von Grund auf entwickeln, um Rohdaten zu überwachen und zu analysieren. Sie besitzen beispielsweise einen durchschnittlich großen Online-Shop. Sie sind mit den bestehenden Lösungen für die Datenanalyse nicht zufrieden und möchten selbst verwalten, welche Komponenten in Ihrem Admin-Panel enthalten sein werden. Es ist auch sehr wichtig, dass Sie mit Rohdaten arbeiten und selbst Analysediagramme erstellen.',

  EXP_TITLE3: 'Datenflüsse für IOT-Projekte',
  EXP_TEXT3:
    'Das Internet der Dinge oder IoT ist ein System miteinander verbundener Computergeräte, mechanischer und digitaler Maschinen, Objekte, Tiere oder Menschen, die mit eindeutigen Identifikatoren (UIDs) und der Fähigkeit ausgestattet sind, Daten über ein Netzwerk zu übertragen, ohne dass eine menschliche Kommunikation erforderlich ist. Mensch- oder Mensch-Computer-Interaktion.',
  EXP_TEXT4:
    'Ein Ding im Internet der Dinge kann eine Person mit einem Herzmonitorimplantat, ein Nutztier mit einem Biochip-Transponder, ein Auto mit eingebauten Sensoren sein, die den Fahrer bei niedrigem Reifendruck warnen, oder irgendein anderes natürliches oder künstliches Ding Objekt, dem eine Internet Protocol (IP)-Adresse zugewiesen werden kann und das in der Lage ist, Daten über ein Netzwerk zu übertragen.',
  EXP_TEXT5:
    'Zunehmend nutzen Unternehmen in verschiedenen Branchen das IoT, um effizienter zu arbeiten, Kunden besser zu verstehen, besseren Kundenservice zu bieten, die Entscheidungsfindung zu verbessern und den Wert des Unternehmens zu steigern.',
  EXP_TITLE6: 'Video streaming',
  EXP_TEXT6:
    'Wir bieten eine Reihe von Entwicklungsdiensten für Video-Streaming-Apps an und verfügen über ein großes Team von Spezialisten, die für die Bereitstellung von Ergebnissen zur Verfügung stehen. Je nach Bedarf können wir schnell laufende Live-Streaming-Plattformen oder Video-on-Demand-Dienste aufbauen. Diese Apps sind nicht nur auf größtmöglichen Komfort ausgelegt (sowohl bei der Benutzererfahrung als auch bei der Administratorverwaltung), sondern sie sind auch robust und bewältigen die Belastung durch große Datenströme fachmännisch.',

  EXP_TITLE7: 'Routenplaner für mehrstufige Logistik',
  EXP_TEXT7: `An der Spitze der nachhaltigen Innovation entwickeln wir modernste Logistiklösungen, die auf die einzigartigen Anforderungen kommerzieller Elektrofahrzeuge (EVs) zugeschnitten sind. Unser fortschrittliches Routenplanungssystem ermöglicht Unternehmen, Routen zu optimieren, Ladezeiten nahtlos zu integrieren und die betriebliche Effizienz von Elektrofahrzeugflotten zu steigern.`,

  _HWORK1: 'Wie wir arbeiten',
  _HWORK2:
    'Keine Idee ist so komplex, dass nicht die optimale architektonische Lösung entwickelt werden kann. Unser Team aus erfahrenen React-Ingenieuren wird Ihrem Projekt umfangreiches institutionelles Wissen über React einbringen, basierend auf unserer praktischen Erfahrung beim Erstellen einer Vielzahl von Anwendungen, von MVP bis hin zu komplexen Apps der Unternehmensklasse.',
  _HWORK3: 'WIR BIETEN',
  _HWORK31: 'Der modernste Technologie-Stack für Front-End und Backend',
  _HWORK32:
    'Best Practices basierend auf unserer Erfahrung mit vielen realen Produktionsprojekten',
  _HWORK34: 'Produktions- und CI/CD-fähige Lösungen',
  _HWORK35: 'Qualifizierte Architekten für React Native, React und Backend',
  _HWORK36: 'Professionelles Feedback zu einem bestehenden Projekt',
  _HWORK37: 'Unterstützung bei der Projektumsetzung von Anfang bis Ende',
  _HWORK4: 'Fokus auf Testen',
  _HWORK5:
    'Das Hauptmerkmal von Node.js ist die hohe Qualität des Codes. Was wiederum bedeutet, dass es stabil und vorhersehbar ist und sein Ziel erreicht. Da Node.js eine dynamisch typisierte Sprache und eine stark typisierte Sprache sein kann, wurde die Qualität durch die Durchführung automatisierter Tests auf dem erforderlichen Niveau gehalten. Das Node.js-Ökosystem bietet mehrere hochmoderne Frameworks für automatisierte Tests. Sie wurden jahrelang von den prominentesten Entwicklern der Community verfeinert und kampferprobt',
  _HWORK6: 'Möchten Sie weitere Beispiele erfahren?',

  // ---------------------------
  _MOBILITY_DESCRIPTION:
    'Effiziente Lösungen für beide Seiten der E-Mobilitätsbranche – EMSP- und CPO-Betreiber. Garantierte nahtlose Erfahrung durch Implementierung offener Standards, Entwicklung von Apps und Plattformen, Domänenberatung und vieles mehr.',
  _MOBILITY_KEYWORDS:
    'react emobility solutions, react ocpp demo, react ocpp management system, ocpp dashboard, ocpp billing system, ocpp charging management',
  _MOBILITY_HEAD_H1: 'E-Mobility solutions',
  _MOBILITY_HEAD_TEXT:
    'Effiziente Lösungen für beide Seiten der E-Mobilitätsbranche - EMSP- und CPO-Betreiber. Garantierte nahtlose Erfahrung durch Implementierung offener Standards, Entwicklung von Apps und Plattformen, Domänenberatung und vieles mehr.',
  _MOBILITY_BACK: 'Zurück zum Kompetenzbereich',
  _MOBILITY_TITLE1: 'Produkte der Zukunft für die nächste Generation',
  _MOBILITY_TEXT1: `Durch Software bieten wir die flexibelsten Softwarelösungen für die EV-Infrastruktur der Branche. Verwalten Sie jeden OCPP-Lader, laden Sie jedes Elektroauto und akzeptieren Sie jede gängige Zahlungsmethode.`,
  _MOBILITY_TEXT2: `Unsere innovativen Produkte gestalten die Zukunft des Automobildesigns. Wir basieren komplexe Automobildesigns auf zukunftsorientierten Lösungen. Als langjähriger und zuverlässiger Partner der Automobilindustrie fertigt voestalpine innovative Produkte vom Design bis zum Prototyp und vom Werkzeugbau bis zur Serienproduktion. Jedes Bauteil, ob Einzelteil oder komplexes Modul, erfüllt die notwendigen Sicherheits- und Gewichtsreduktionsanforderungen. Auf diese Weise trägt jede innovative Komponente zu einer besseren Zukunft bei.`,
  _MOBILITY_TITLE2: `Lass uns anfangen`,
  _MOBILITY_TEXT3: `Das Laden von Elektrofahrzeugen muss nicht kompliziert sein. Wir können Ihre End-to-End-EV-Infrastrukturlösung mit vielen benutzerdefinierten Einstellungen und flexiblen Preisen für Ihre Kunden bereitstellen.`,
  _MOBILITY_TITLE4: `EV-Lademanagement`,
  _MOBILITY_TEXT4: `Verbinden und steuern Sie jeden OCPP-EV-Lader. Sie können eigene Softwarepläne ab $0 erstellen. Kostenlose Stunden, Rabatte zu verschiedenen Zeiten ermöglichen es Ihnen, Gamification- und Marketingfunktionen zu Ihren Stationen hinzuzufügen (unterstützt OCCP-Protokolle und die bekanntesten EV-Ladegerätehersteller).`,
  _MOBILITY_TITLE5: `EV-Management-Integrationen mit CRM`,
  _MOBILITY_TEXT5: `Wir können unsere Managementsysteme für Ladegeräte einfach in Ihr CRM-System, Benachrichtigungsdienste integrieren und jede Komplexitätsintegration mit Drittanbieter-Software implementieren.`,
  _MOBILITY_MORE_BUTTON: 'Mehr über EV-Laden erfahren',
  _MOBILITY_TITLE6: `EV-Fahrerkonto`,
  _MOBILITY_TEXT6: `Keine Mitgliedsgebühren oder Wallet-Einzahlungen erforderlich, jetzt oder jemals.`,

  // ---------------------------

  _CHARGING_TITLE: 'EV-Ladestationssoftware (OCPP)',
  _CHARGING_DESCRIPTION:
    'Maximieren Sie die Rentabilität Ihres Ladenetzwerks und erleben Sie unsere hochmoderne EV-Ladestationssoftware für Betreiber und Dienstleister.',
  _CHARGING_TITLE1: 'Software für die EV-Ladeindustrie',
  _CHARGING_TEXT1:
    'Nutzen Sie unsere White-Label-EV-Ladestationssoftware, um Ihre EV-Ladeinfrastruktur einfach zu verwalten. Alle Ladestationen werden in einem mehrstufigen Webportal mit getrennten Rollen und Verantwortlichkeiten verwaltet – Sie entscheiden, wer auf das System zugreifen darf. Wir bieten sowohl ausgelagerte als auch vollständig eigenverwaltete Pakete unserer EV-Ladeverwaltungssoftware an, die auf Ihre geschäftlichen Bedürfnisse zugeschnitten sind.',
  _CHARGING_TITLE2: 'Lademanagement leicht gemacht',
  _CHARGING_TEXT2:
    'Lademanagement-Software ermöglicht es Ihnen, das Laden von Elektrofahrzeugen zu verfolgen, zu verwalten und zu optimieren und zusätzliches Einkommen zu generieren. Vom Zugriff auf erweiterte Einblicke über unser Business-Portal bis hin zur Verwaltung des Ladens unterwegs über unsere Charge-App war der Umstieg auf Elektromobilität noch nie so einfach.',
  _CHARGING_TITLE3: 'Optimale Netzwerknutzung mit EV Smart Charging',
  _CHARGING_TEXT3:
    'Wenn viele Elektrofahrzeuge gleichzeitig im selben Netz laden, besteht die Gefahr einer Überlastung des Energienetzes. Mit Smart Charging können Sie die Anzahl der gleichzeitig ladenden Elektrofahrzeuge erhöhen, ohne zusätzliche Investitionen in das Netz tätigen zu müssen.',
  _CHARGING_TITLE4: 'EV-Ladestationsverwaltung',
  _CHARGING_TEXT4:
    'Verbinden und steuern Sie jede OCPP-EV-Ladestation. Sie können eigene Softwarepläne ab 0 $ erstellen. Freistunden, Rabatte zu verschiedenen Zeiten ermöglichen es Ihnen, Gamification- und Marketingfunktionen zu Ihren Stationen hinzuzufügen (Unterstützung von OCCP-Protokollen und den bekanntesten EV-Ladestationsherstellern).',
  _CHARGING_TITLE5: 'Infrastrukturmanagement',
  _CHARGING_TEXT51:
    'Verwalten Sie EV-Ladestationen, Ladegeräte und Anschlüsse.',
  _CHARGING_TEXT52: 'Überwachen Sie Sitzungen, Stationen und Ladegeräte.',
  _CHARGING_TEXT53: 'Intelligentes Energiemanagement mit Lastverteilung.',
  _CHARGING_TEXT54: 'Beheben Sie Probleme.',
  _CHARGING_TEXT55:
    'Wenden Sie Fernbefehle an (Neustart, Firmware-Update usw.).',
  _CHARGING_TEXT56: 'Dashboard mit Echtzeitdaten und Google Maps.',
  _CHARGING_TEXT57:
    'Hardware-Flexibilität durch Open Charge Point Protocol – OCPP.',
  _CHARGING_TITLE6: 'API-Plattformlösung',
  _CHARGING_TEXT6:
    'Passen Sie Ihr EV-Ladeerlebnis mit unserer innovativen und offenen Ladenetzwerkplattform für Software und Dienstleistungen an. Unsere API-Plattform ermöglicht Betreibern, eigene Benutzererlebnisse über ein leistungsstarkes API-Gateway zu erstellen, mit dem Sie Ihre eigene Fahrer-App entwickeln und das Managementportal nutzen können, um Ihre Stationen zu verwalten. Ob Sie Ihre aktuelle mobile App integrieren, das Laden in Belohnungen oder Mitgliedschaften einbinden oder andere eMobility-Dienste nutzen möchten – unsere API-Plattform unterstützt Ihre Anforderungen. Sie eignet sich auch perfekt für die Integration in Flottenmanagement, Energiemanagementlösungen und Fahrzeugetelematiksysteme.',

  _DASHBOARD_TITLE: 'Dashboard-Entwicklung (React)',
  _DASHBOARD_DESCRIPTION:
    'Maximieren Sie die Rentabilität Ihres Ladenetzwerks und erleben Sie unsere hochmoderne Software für Betreiber und Dienstleister von EV-Ladestationen.',
  _DASHBOARD_TITLE1: 'React-Entwicklungsdienste',
  _DASHBOARD_TEXT1:
    'Möchten Sie eine dynamische und interaktive Single-Page-Anwendung erstellen lassen? Suchen Sie nicht weiter! Wir bieten ReactJS-Entwicklungsdienste, die all Ihren Entwicklungsanforderungen gerecht werden. Die von uns erstellten ReactJS-Anwendungen sind hochfunktional und bieten ein natives Gefühl für Ihre mobilen Apps. Selbstverständlich sind sie leichtgewichtig, schnell und reaktionsschnell.',
  _DASHBOARD_BACK: 'Zurück zum Fachbereich',
  _DASHBOARD_TITLE2: 'Unternehmenslösungen. Höchstleistung. Code-Qualität.',
  _DASHBOARD_TEXT21:
    'Wir haben ein engagiertes Team von React-Entwicklern, die die Flexibilität und Dynamik von React JS nutzen, um webbasierte Anwendungen mit höchster Sicherheit zu entwickeln. Unsere React-Entwicklungsdienste helfen dabei, datengesteuerte Webanwendungen mit großer Geschwindigkeit, Einfachheit und Robustheit zu erstellen.',
  _DASHBOARD_TEXT22:
    'React JS ist die primäre Frontend-Plattform, auf der wir Benutzeroberflächen und UI-Komponenten erstellen. Alle unsere Teams verfügen über umfangreiches Wissen und Erfahrung in der React-Entwicklung und haben an Dutzenden von Projekten gearbeitet.',
  _DASHBOARD_TEXT23:
    'Zugriff nur auf erfahrene React.js-Entwickler mit Erfahrung in der Entwicklung groß angelegter Webanwendungen. Qualitätsstandards definiert durch über 200 Projekte.',
  _DASHBOARD_BUTTON: 'Zeigen Sie mir Beispiele',
  _DASHBOARD_TITLE3: 'Morgen beginnen',
  _DASHBOARD_TEXT3:
    'Sie müssen Ihre interne Organisation nicht aufbauen. Beauftragen Sie unser Entwicklerteam für das Projekt und ersetzen Sie uns später durch internes Personal.',
  _DASHBOARD_TITLE4: 'React.js-Entwicklung von Grund auf',
  _DASHBOARD_TEXT4:
    'Sie können die React-Entwicklung an uns auslagern, und wir werden ein Projekt von Grund auf für Sie erstellen.',
  _DASHBOARD_TITLE5: 'Migration zu React',
  _DASHBOARD_TEXT5:
    'Unsere Experten führen eine hochwertige Migration zu React von jedem anderen Frontend-Framework durch.',
  _DASHBOARD_TITLE6: 'Dediziertes React.js-Entwicklungsteam',
  _DASHBOARD_TEXT6:
    'Stellen Sie dedizierte React.js-Entwickler ein, die Ihren Anforderungen in großem Maßstab entsprechen. Sie sind auch in der Lage, schlecht geschriebenen Code einer anderen React.js-Entwicklungsfirma zu überarbeiten, um Industriestandards zu erfüllen.',
  _DASHBOARD_TITLE7: 'React Native App-Entwicklung',
  _DASHBOARD_TEXT7:
    'Erleben Sie die volle Leistungsfähigkeit des führenden plattformübergreifenden Mobile-App-Entwicklungsframeworks.',

  _AI_TITLE:
    'Webentwicklung der nächsten Generation: Schnelle und qualitativ hochwertige Lösungen mit Node.js, React.js und KI',
  _AI_TEXT:
    'In der heutigen digitalen Welt ist Webentwicklung zur Grundlage eines erfolgreichen Geschäfts geworden. Wir sind auf die Erstellung dynamischer, skalierbarer und zuverlässiger Weblösungen spezialisiert, die höchste Leistung und Benutzerfreundlichkeit gewährleisten. Unsere Tools — Node.js, React.js und künstliche Intelligenz (KI) — ermöglichen es uns, Lösungen zu erstellen, die genau auf Ihre Geschäftsanforderungen zugeschnitten sind.',
  _AI_KEYWORDS:
    'Webentwicklung, Erstellung von Landingpages, Node.js-Entwicklung, React.js-Entwicklung, KI-Entwicklung, Webanwendungen, Website-Erstellung, schlüsselfertige Entwicklung, Modernisierung von Webanwendungen, Integration künstlicher Intelligenz, interaktive Weblösungen',
  _AI_TITLE1: 'Unser Ansatz:',
  _AI_SECTION1: '1. Geschwindigkeit und Effizienz',
  _AI_TEXT1:
    'Wir verwenden Node.js für die Backend-Entwicklung, um eine blitzschnelle Datenverarbeitung zu gewährleisten, und React.js für die Erstellung interaktiver Benutzeroberflächen. Diese Kombination ermöglicht eine schnellere Projektdurchführung bei minimalen Kosten.',
  _AI_SECTION2: '2. Qualität und Zuverlässigkeit',
  _AI_TEXT2:
    'Wir streben in jeder Phase der Entwicklung nach höchster Qualität. Unsere Experten überwachen jeden Schritt des Projekts sorgfältig, um seine Zuverlässigkeit und Stabilität zu gewährleisten.',
  _AI_SECTION3: '3. KI-Integration',
  _AI_TEXT3:
    'Wir nutzen Technologien der künstlichen Intelligenz, um intelligente und innovative Lösungen zu entwickeln, die Geschäftsprozesse optimieren und die Effizienz steigern. Bringen Sie Innovation in Ihr Projekt! Wir integrieren KI-Funktionen wie:',
  _AI_TEXT31: 'Prozessautomatisierung',
  _AI_TEXT32: 'Personalisierte Benutzererfahrung',
  _AI_TEXT33: 'Big-Data-Analyse für Entscheidungsfindung',
  _AI_TITLE2: 'Unsere Dienstleistungen:',
  _AI_SECTION4: 'Erstellung von Webanwendungen von Grund auf',
  _AI_TEXT4:
    'Ein vollständiger Entwicklungszyklus — von der Idee bis zum fertigen Produkt. Wir entwickeln leistungsstarke Webanwendungen, die Ihre geschäftlichen Herausforderungen effektiv lösen.',
  _AI_SECTION5: 'Migration zu modernen Plattformen',
  _AI_TEXT5:
    'Migration veralteter Anwendungen auf moderne Technologien wie Node.js und React.js zur Verbesserung von Leistung und Benutzeroberfläche.',
  _AI_SECTION6: 'KI-Integration in bestehende Systeme',
  _AI_TEXT6:
    'Wir helfen dabei, maschinelles Lernen, Chatbots und andere KI-Lösungen in Ihr Projekt zu integrieren.',
  _AI_TITLE7: 'Bereit, zu starten?',
  _AI_TEXT7:
    'Kontaktieren Sie uns noch heute, um Ihr Projekt zu besprechen und zu erfahren, wie wir Ihrem Unternehmen mit modernen Technologien wie Node.js, React.js und KI zum Wachstum verhelfen können.'
};

export default translate;
