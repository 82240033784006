import _ from 'lodash';

import en from './_en';
import ua from './_ua';
import ru from './_ru';
import de from './_de';
import pl from './_pl';
import fr from './_fr';
import es from './_es';
import be from './_be';

const dictionaries = {
  en,
  be,
  ua,
  de,
  pl,
  fr,
  es,
  ru
};

export const languageTitles = {
  en: 'English',
  be: 'Беларуская',
  ua: 'Українська',
  pl: 'Polski',
  de: 'Deutsch',
  ru: 'Русский',
  es: 'Español',
  // zh: '中文',
  fr: 'Français'
};

const translate = (name, lang, dictionary = {}) => {
  const currentDict = dictionaries[lang] || en;
  return _.get(dictionary, `${lang}.${name}`) || currentDict[name] || en[name];
};

export const languages = Object.keys(dictionaries);

export default translate;
