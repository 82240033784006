const translate = {
  _SEO_TITLE1: 'Contrata desarrolladores de clase mundial para tu empresa',
  _SEO_TITLE2:
    'Ofrecemos servicios de desarrollo con React.js, ayudando a empresas y startups a realizar sus proyectos soñados. Siempre buscamos las mejores soluciones para los negocios de nuestros clientes. Una de estas soluciones es usar React.js para desarrollar servicios en línea y aplicaciones web.',
  _SEO_TITLE3:
    'Desarrollo de aplicaciones React, desarrollo Node.js, aplicaciones React, renderizado del lado del servidor, aplicaciones SSR, aplicaciones React SSR',
  _HOME_LINK1: 'Expertise',
  _HOME_LINK2: 'Nuestro método',
  _HOME_LINK3: 'AI Solutions',
  _HOME_CONTACT: 'Contáctanos',
  _HOME_SECTION3: 'Nuestra experiencia',

  EXP_DESCRIPTION:
    'Desarrollo profesional en React: creación de aplicaciones web rápidas, responsivas y escalables. Tecnologías modernas, UX/UI de alto nivel y optimización para los objetivos de su negocio.',
  EXT_T1: 'NUESTRA EXPERTISE',
  EXT_TT:
    'Nuestro equipo puede proporcionar una amplia gama de conocimientos en tecnologías de software para móvil, web, escritorio y empresarial en todos los principales entornos de desarrollo.',

  WANT_BUTTON: '¿Quieres saber más?',
  EXP_TITLE1: 'Soluciones de movilidad eléctrica',
  EXP_TEXT1:
    'Soluciones eficientes para ambos lados de la industria de la movilidad eléctrica: operadores EMSP y CPO. Garantizamos una experiencia fluida gracias a la implementación de estándares abiertos, desarrollo de aplicaciones y plataformas, consultoría de dominio, y mucho más.',

  EXP_TITLE2: 'Todo tipo de aplicaciones React',
  EXP_TEXT2:
    'Imagina que necesitas desarrollar una aplicación web desde cero para monitorear y analizar datos en bruto. Por ejemplo, posees una tienda en línea de tamaño mediano. No estás satisfecho con las soluciones existentes para el análisis de datos y deseas manejar tú mismo los componentes de tu panel administrativo. También es muy importante para ti trabajar con datos en bruto y crear gráficos analíticos tú mismo.',

  EXP_TITLE3: 'Flujo de datos para proyectos IoT',
  EXP_TEXT3:
    'El Internet de las cosas (IoT) es un sistema de dispositivos informáticos interconectados, máquinas mecánicas y digitales, objetos, animales o personas con identificadores únicos (UID) y capaces de transferir datos a través de una red sin requerir interacción humana directa.',
  EXP_TEXT4:
    'Un objeto en el Internet de las cosas puede ser una persona con un implante de monitor cardíaco, un animal con un chip bioelectrónico, un coche equipado con sensores que alertan al conductor cuando la presión de los neumáticos es baja, o cualquier otro objeto natural o fabricado que pueda tener una dirección IP y ser capaz de transferir datos.',
  EXP_TEXT5:
    'Cada vez más organizaciones en diversos sectores utilizan IoT para operar de manera más eficiente, entender mejor a sus clientes, ofrecer un mejor servicio al cliente, tomar mejores decisiones y aumentar el valor empresarial.',

  EXP_TITLE6: 'Streaming de video',
  EXP_TEXT6:
    'Ofrecemos una gama de servicios de desarrollo de aplicaciones de streaming de video, con un amplio equipo de especialistas listo para entregar resultados. Según tus necesidades, podemos diseñar plataformas de streaming en vivo rápidas o servicios de video bajo demanda. Estas aplicaciones están diseñadas no solo para brindar la máxima comodidad (tanto en la experiencia del usuario como en la gestión administrativa), sino que también son robustas y gestionan eficazmente flujos de tráfico intensos.',

  EXP_TITLE7: 'Planificador de rutas para logística con múltiples paradas',
  EXP_TEXT7: `A la vanguardia de la innovación sostenible, desarrollamos soluciones logísticas de última generación adaptadas a las necesidades únicas de los vehículos eléctricos comerciales. Nuestro avanzado sistema de planificación de rutas permite a las empresas optimizar rutas, integrar horarios de carga sin problemas y mejorar la eficiencia operativa general de las flotas de vehículos eléctricos.`,

  _HWORK1: 'Cómo trabajamos',
  _HWORK2:
    'No existe idea tan compleja que no se pueda desarrollar una solución arquitectónica óptima. Nuestro equipo de ingenieros experimentados en React aportará a tu proyecto una gran cantidad de conocimientos prácticos sobre React, basados en nuestra experiencia real construyendo una variedad de aplicaciones, desde MVP hasta aplicaciones empresariales complejas.',
  _HWORK3: 'LO QUE OFRECEMOS',
  _HWORK31: 'el stack tecnológico más moderno para Front-end y Back-end',
  _HWORK32:
    'mejores prácticas basadas en nuestra experiencia con muchos proyectos reales en producción',
  _HWORK34: 'soluciones listas para producción y CI/CD',
  _HWORK35: 'arquitectos cualificados para React Native, React y Back-end',
  _HWORK36: 'retroalimentación profesional sobre un proyecto existente',
  _HWORK37: 'soporte en la implementación del proyecto de principio a fin',
  _HWORK4: 'Enfoque en pruebas',
  _HWORK5:
    'La característica clave de Node.js es la alta calidad del código. Esto significa que es estable, predecible y cumple su objetivo. Dado que Node.js puede ser un lenguaje dinámicamente tipado y fuertemente tipado, la calidad se mantiene al nivel requerido mediante el uso de pruebas automatizadas. El ecosistema de Node.js ofrece varios frameworks de pruebas automatizadas de última generación que han sido refinados y probados durante años por los desarrolladores más destacados de la comunidad.',
  _HWORK6: '¿Quieres conocer más ejemplos?',

  // ---------------------------
  _MOBILITY_DESCRIPTION:
    'Aquí puedes encontrar nuestra sección de experiencia en movilidad eléctrica en desarrollo de software',
  _MOBILITY_KEYWORDS:
    'soluciones de movilidad eléctrica react, demostración react ocpp, sistema de gestión react ocpp, panel ocpp, sistema de facturación ocpp, gestión de carga ocpp',
  _MOBILITY_HEAD_H1: 'Soluciones de Movilidad Eléctrica',
  _MOBILITY_HEAD_TEXT:
    'Soluciones eficientes para ambos lados de la industria de la movilidad eléctrica: operadores EMSP y CPO. Garantizamos una experiencia fluida mediante la implementación de estándares abiertos, desarrollo de aplicaciones y plataformas, consultoría de dominio y mucho más.',
  _MOBILITY_BACK: 'Volver a la sección de experiencia',
  _MOBILITY_TITLE1: 'Productos del futuro para la próxima generación',
  _MOBILITY_TEXT1:
    'A través del software, traemos al mercado las soluciones de infraestructura EV más flexibles. Gestiona cualquier cargador OCPP, carga cualquier coche eléctrico y acepta todos los métodos de pago principales.',
  _MOBILITY_TEXT2:
    'Nuestros productos innovadores están dando forma al futuro del diseño automotriz. Basamos los diseños automotrices complejos en soluciones visionarias. Como socio confiable de la industria automotriz, fabricamos productos innovadores desde el diseño hasta el prototipo, desde la fabricación de herramientas hasta la producción en serie. Cada componente, ya sea una pieza individual o un módulo complejo, cumple con los requisitos necesarios de seguridad y reducción de peso. De esta manera, cada componente innovador contribuye a un futuro más brillante.',
  _MOBILITY_TITLE2: 'Comencemos',
  _MOBILITY_TEXT3:
    'La carga EV no tiene por qué ser complicada. Podemos ofrecerte una solución completa de infraestructura EV con muchas configuraciones personalizadas y precios flexibles para tus clientes.',
  _MOBILITY_TITLE4: 'Gestión de cargadores EV',
  _MOBILITY_TEXT4:
    'Conecta y controla cualquier cargador EV OCPP. Puedes crear tus propios planes de software desde $0. Horas gratis, descuentos en diferentes rangos de tiempo te permiten agregar características de gamificación y marketing a tus estaciones (compatible con protocolos OCPP y los fabricantes de cargadores EV más conocidos).',
  _MOBILITY_TITLE5: 'Integraciones de gestión EV con CRM',
  _MOBILITY_TEXT5:
    'Podemos integrar fácilmente nuestros sistemas de gestión de cargadores a tu sistema CRM, servicios de notificaciones e implementar integraciones de cualquier complejidad con software de terceros.',
  _MOBILITY_MORE_BUTTON: 'Más sobre carga EV',
  _MOBILITY_TITLE6: 'Cuenta de conductor EV',
  _MOBILITY_TEXT6:
    'Sin tarifas de membresía ni depósitos en cartera requeridos, ahora ni nunca.',

  // ---------------------------

  _CHARGING_TITLE: 'Software de carga EV (OCPP)',
  _CHARGING_DESCRIPTION:
    'Maximiza la rentabilidad de tu red de carga y experimenta nuestro software de estaciones de carga EV de última generación para operadores y proveedores de servicios.',
  _CHARGING_TITLE1: 'Software para la industria de carga EV',
  _CHARGING_TEXT1:
    'Utiliza nuestro software de carga EV de etiqueta blanca para gestionar fácilmente tu infraestructura de carga EV. Todos los puntos de carga se administran en un portal web multinivel con roles y responsabilidades separados: tú decides quién puede acceder al sistema. Ofrecemos paquetes de gestión de carga EV externalizados o totalmente autogestionados, adaptados a las necesidades de tu negocio.',
  _CHARGING_TITLE2: 'Gestión de carga simplificada',
  _CHARGING_TEXT2:
    'El software de gestión de carga te permite rastrear, gestionar y optimizar la carga de vehículos eléctricos y generar ingresos adicionales. Desde acceder a información avanzada a través de nuestro Portal de Negocios hasta gestionar la carga en movimiento con nuestra aplicación Charge, pasarse a lo eléctrico nunca ha sido tan fácil.',
  _CHARGING_TITLE3: 'Usa la red de manera óptima con carga EV inteligente',
  _CHARGING_TEXT3:
    'Si muchos vehículos eléctricos se cargaran al mismo tiempo en la misma red, existe la posibilidad de que la red energética se sobrecargue. Con la carga inteligente, puedes aumentar el número de vehículos eléctricos que se pueden cargar simultáneamente sin realizar inversiones adicionales en la red.',
  _CHARGING_TITLE4: 'Gestión de cargadores EV',
  _CHARGING_TEXT4:
    'Conecta y controla cualquier cargador EV OCPP. Puedes crear tus propios planes de software desde $0. Horas gratis, descuentos en diferentes rangos de tiempo te permiten agregar características de gamificación y marketing a tus estaciones (compatible con protocolos OCPP y los fabricantes de cargadores EV más conocidos).',
  _CHARGING_TITLE5: 'Gestión de Infraestructura',
  _CHARGING_TEXT51: 'Gestiona estaciones de carga EV, cargadores y conectores.',
  _CHARGING_TEXT52: 'Monitorea sesiones, estaciones y cargadores.',
  _CHARGING_TEXT53: 'Gestión inteligente de energía con balance de carga.',
  _CHARGING_TEXT54: 'Solución de problemas.',
  _CHARGING_TEXT55:
    'Aplica comandos remotos (reinicio, actualización de firmware, etc.).',
  _CHARGING_TEXT56:
    'Panel de control con datos en tiempo real y mapas de Google.',
  _CHARGING_TEXT57:
    'Flexibilidad de hardware mediante el Protocolo de Punto de Carga Abierto (OCPP).',
  _CHARGING_TITLE6: 'Solución de Plataforma API',
  _CHARGING_TEXT6:
    'Personaliza tu experiencia de carga EV con nuestra innovadora y abierta plataforma de red de carga compuesta por software y servicios. Nuestra plataforma API permite a los operadores crear su propia experiencia de usuario a través de una potente pasarela API que te permite desarrollar tu propia aplicación para conductores y aprovechar el portal de gestión para administrar tus estaciones. Ya sea que quieras integrar tu aplicación móvil actual, vincular la carga con recompensas o membresías, o disponer de otros servicios de e-Movilidad, nuestra plataforma API respalda tus necesidades. También es perfecta para la integración en operaciones de flotas, soluciones de gestión energética y sistemas de telemática vehicular.',

  _DASHBOARD_TITLE: 'Desarrollo de paneles (React)',
  _DASHBOARD_DESCRIPTION:
    'Maximiza la rentabilidad de tu red de carga y experimenta nuestro software de última generación para operadores y proveedores de servicios de estaciones de carga para vehículos eléctricos.',
  _DASHBOARD_TITLE1: 'Servicios de desarrollo React',
  _DASHBOARD_TEXT1:
    '¿Quieres construir una aplicación de página única dinámica e interactiva? ¡No busques más! Ofrecemos servicios de desarrollo ReactJS para satisfacer todas tus necesidades. Las aplicaciones ReactJS que desarrollamos son altamente funcionales y ofrecen una sensación nativa en tus aplicaciones móviles. Naturalmente, son ligeras, rápidas y receptivas.',
  _DASHBOARD_BACK: 'Volver a la sección de experiencia',
  _DASHBOARD_TITLE2:
    'Soluciones empresariales. Máximo rendimiento. Calidad de código.',
  _DASHBOARD_TEXT21:
    'Contamos con un equipo dedicado de desarrolladores React que aprovechan la flexibilidad y dinamismo de React JS para desarrollar aplicaciones web dirigidas al consumidor con los más altos niveles de seguridad. Nuestros servicios ayudan a desarrollar aplicaciones web basadas en datos con gran velocidad, simplicidad y robustez.',
  _DASHBOARD_TEXT22:
    'React JS es la plataforma principal de front-end en la que construimos interfaces de usuario y componentes de UI. Todos nuestros equipos tienen un amplio conocimiento y experiencia en desarrollo React y han trabajado en docenas de proyectos.',
  _DASHBOARD_TEXT23:
    'Accede solo a desarrolladores experimentados de React.js con experiencia comprobada en desarrollo web a gran escala. Estándares de calidad definidos por más de 200 proyectos.',
  _DASHBOARD_BUTTON: 'Muéstrame ejemplos',
  _DASHBOARD_TITLE3: 'Empieza mañana',
  _DASHBOARD_TEXT3:
    'No necesitas construir tu organización interna. Contrata a nuestro equipo de desarrolladores para el proyecto y reemplázanos con personal interno más tarde.',
  _DASHBOARD_TITLE4: 'Desarrollo de React.js desde cero',
  _DASHBOARD_TEXT4:
    'Puedes subcontratar el desarrollo de React con nosotros, y construiremos un proyecto desde cero para ti.',
  _DASHBOARD_TITLE5: 'Migración a React',
  _DASHBOARD_TEXT5:
    'Nuestros expertos realizarán una migración de alta calidad a React desde cualquier otro framework de front-end.',
  _DASHBOARD_TITLE6: 'Equipo dedicado de desarrollo React.js',
  _DASHBOARD_TEXT6:
    'Contrata desarrolladores dedicados de React.js para satisfacer tus necesidades a escala. También están capacitados para refactorizar el código mal escrito por otra empresa de desarrollo React.js para cumplir con los estándares de la industria.',
  _DASHBOARD_TITLE7: 'Desarrollo de aplicaciones React Native',
  _DASHBOARD_TEXT7:
    'Experimenta todo el poder del principal framework de desarrollo de aplicaciones móviles multiplataforma.',

  _AI_TITLE:
    'Desarrollo web de próxima generación: Soluciones rápidas y de alta calidad con Node.js, React.js y AI',
  _AI_TEXT:
    'En el mundo digital actual, el desarrollo web se ha convertido en la base de un negocio exitoso. Nos especializamos en crear soluciones web dinámicas, escalables y confiables que garantizan un alto rendimiento y facilidad de uso. Nuestras herramientas — Node.js, React.js y las tecnologías de inteligencia artificial (AI) — nos permiten crear soluciones perfectamente adaptadas a las necesidades de su negocio.',
  _AI_KEYWORDS:
    'desarrollo web, creación de landing pages, desarrollo Node.js, desarrollo React.js, desarrollo AI, aplicaciones web, creación de sitios web, desarrollo llave en mano, modernización de aplicaciones web, integración de inteligencia artificial, soluciones web interactivas',
  _AI_TITLE1: 'Nuestro enfoque:',
  _AI_SECTION1: '1. Velocidad y eficiencia',
  _AI_TEXT1:
    'Utilizamos Node.js para el desarrollo backend, garantizando un procesamiento de datos ultrarrápido, y React.js para diseñar interfaces de usuario interactivas. Esta combinación permite una entrega rápida de proyectos con costos mínimos.',
  _AI_SECTION2: '2. Calidad y confiabilidad',
  _AI_TEXT2:
    'Nos esforzamos por alcanzar la máxima calidad en cada etapa del desarrollo. Nuestros expertos supervisan cuidadosamente cada paso del proyecto para garantizar su fiabilidad y estabilidad.',
  _AI_SECTION3: '3. Integración de AI',
  _AI_TEXT3:
    'Utilizamos tecnologías de inteligencia artificial para crear soluciones inteligentes e innovadoras que optimizan los procesos empresariales y mejoran la eficiencia de su negocio. ¡Aporta innovación a tu proyecto! Integramos capacidades de AI como:',
  _AI_TEXT31: 'Automatización de procesos',
  _AI_TEXT32: 'Personalización de la experiencia del usuario',
  _AI_TEXT33: 'Análisis de big data para la toma de decisiones',
  _AI_TITLE2: 'Nuestros servicios:',
  _AI_SECTION4: 'Creación de aplicaciones web desde cero',
  _AI_TEXT4:
    'Un ciclo completo de desarrollo, desde la idea hasta el producto final. Desarrollamos aplicaciones web potentes que resuelven eficazmente los desafíos de su negocio.',
  _AI_SECTION5: 'Migración a plataformas modernas',
  _AI_TEXT5:
    'Migración de aplicaciones obsoletas a tecnologías modernas como Node.js y React.js para mejorar el rendimiento y la interfaz.',
  _AI_SECTION6: 'Integración de AI en sistemas existentes',
  _AI_TEXT6:
    'Ayudamos a integrar soluciones de AI como aprendizaje automático, chatbots y más en su proyecto.',
  _AI_TITLE7: '¿Listo para empezar?',
  _AI_TEXT7:
    'Contáctenos hoy mismo para discutir su proyecto y descubrir cómo podemos ayudar a su negocio a crecer con tecnologías modernas como Node.js, React.js y AI.'
};

export default translate;
