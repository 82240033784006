import React from 'react';
import { Helmet } from 'react-helmet';

import Section from '../../components/Section';
import Robot from '../../components/RobotAnimated';
import WriteUs from '../../components/WriteUs';

import metaValues from '../../../config';

import styles from './styles.module.scss';

const HowWeWork = () => {
  const { defaultTitle } = metaValues;

  return (
    <section className={styles.section}>
      <Helmet>
        <title>Contacts - {defaultTitle}</title>
        <meta name="description" content="ReactAPP team contacts" />
        <meta property="og:title" content={defaultTitle} />
        <meta property="og:image" content="/public/react-application.png" />
      </Helmet>

      <Section className={styles.sand}>
        <Robot />
        <div className={styles.form}>
          <WriteUs />
        </div>
      </Section>
    </section>
  );
};

export default HowWeWork;
