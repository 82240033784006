import React, { memo } from 'react';
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';
import * as Scroll from 'react-scroll';
import { useSelector } from 'react-redux';

// import Button from '../../components/Button';
import Section from '../../components/Section';
import Button from '../../components/Button';
import styles from './emobility.module.scss';

import emobilityImage from '../../assets/img/emobility/be-energised.png';
import chargerImage from '../../assets/img/emobility/charger.svg';

import metaValues from '../../../config';
import translation from '../../translate';

const EMobilityScreen = () => {
  const { defaultTitle } = metaValues;
  const { language } = useSelector(({ common }: any) => common);

  return (
    <div>
      <Helmet>
        <title>E-Mobility solutions - {defaultTitle}</title>
        <meta
          name="description"
          content={translation('_MOBILITY_DESCRIPTION', language)}
        />
        <meta
          property="keywords"
          content={translation('_MOBILITY_KEYWORDS', language)}
        />
        <meta property="og:title" content={defaultTitle} />
        <meta property="og:image" content="/public/productivity.svg" />
      </Helmet>

      <div className={styles.background}>
        <div className={styles.content}>
          <div className={styles.title}>
            <h1>{translation('_MOBILITY_HEAD_H1', language)}</h1>
            <p>{translation('_MOBILITY_HEAD_TEXT', language)}</p>
          </div>
        </div>
      </div>

      <Section className={styles.content}>
        <Link
          to={`/${language}/expertise`}
          onClick={() => Scroll.animateScroll.scrollToTop({ duration: 100 })}
        >
          {translation('_MOBILITY_BACK', language)}
        </Link>
        <h1>{translation('_MOBILITY_TITLE1', language)}</h1>
        <img className={styles.eImage} src={emobilityImage} alt="EV Charging" />
        <p>{translation('_MOBILITY_TEXT1', language)}</p>
        <p>{translation('_MOBILITY_TEXT2', language)}</p>

        <h1>{translation('_MOBILITY_TITLE2', language)}</h1>
        <img
          className={styles.eImage}
          src={chargerImage}
          alt="EV Charge management system"
        />

        <p>{translation('_MOBILITY_TEXT3', language)}</p>
        <Link
          to={`/${language}/expertise/ev-charging`}
          onClick={() => Scroll.animateScroll.scrollToTop({ duration: 100 })}
        >
          <Button icon="Menu" size="large">
            {translation('_MOBILITY_MORE_BUTTON', language)}
          </Button>
        </Link>

        <h3>{translation('_MOBILITY_TITLE4', language)}</h3>
        <p>{translation('_MOBILITY_TEXT4', language)}</p>

        <h3>{translation('_MOBILITY_TITLE5', language)}</h3>
        <p>{translation('_MOBILITY_TEXT5', language)}</p>

        <h3>{translation('_MOBILITY_TITLE6', language)}</h3>
        <p>{translation('_MOBILITY_TEXT6', language)}</p>
      </Section>
    </div>
  );
};

export default memo(EMobilityScreen);
