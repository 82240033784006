const translate = {
  _SEO_TITLE1: 'Найміть світових розробників для вашого бізнесу',
  _SEO_TITLE2:
    'Ми надаємо послуги з розробки React.js, допомагаючи бізнесу та стартапам створювати свої проекти мрії. Ми завжди шукаємо найкращі рішення для бізнесу наших клієнтів. Одним з таких рішень є використання React.js для розробки онлайн-сервісів та веб-додатків.',
  _SEO_TITLE3:
    'Розробка додатків на React, розробка на Nodejs, додатки на React, серверний рендеринг, SSR додатки, SSR додаток на React',
  _HOME_LINK1: 'Експертиза',
  _HOME_LINK2: 'Як ми працюємо',
  _HOME_LINK3: 'AI рішення',
  _HOME_CONTACT: `Зв'язок`,
  _HOME_TITLE1: 'ШВИДКИЙ',
  _HOME_TITLE2: 'РОЗВИТОК',
  _HOME_SECTION3: 'Наш досвід',

  EXP_DESCRIPTION:
    'Професійна розробка на React — створення швидких, адаптивних та масштабованих веб-застосунків. Сучасні технології, високий рівень UX/UI та оптимізація під цілі вашого бізнесу.',
  EXT_T1: 'НАША ЕКСПЕРТИЗА',
  EXT_TT:
    'Наша команда може надати широкий спектр технологічної експертизи в мобільних, веб, настільних та корпоративних програмних середовищах.',

  WANT_BUTTON: 'Хочете дізнатися більше?',
  EXP_TITLE1: 'Рішення для електромобільності',
  EXP_TEXT1:
    'Ефективні рішення для обох сторін індустрії електромобільності - операторів EMSP та CPO. Гарантований безперебійний досвід завдяки впровадженню відкритих стандартів, розробці додатків та платформ, консультуванню в галузі та багато іншого.',

  EXP_TITLE2: 'Будь-які типи додатків на React',
  EXP_TEXT2:
    'Уявіть, що вам потрібно розробити веб-додаток з нуля для моніторингу та аналізу сирих даних. Наприклад, ви володієте середнім інтернет-магазином. Вас не задовольняють існуючі рішення для аналітики даних, і ви хочете самостійно керувати компонентами вашої панелі адміністратора. Також дуже важливо для вас працювати з сирими даними та самостійно створювати аналітичні графіки.',

  EXP_TITLE3: 'Потоки даних для проектів IoT',
  EXP_TEXT3: `Інтернет речей, або IoT, - це система взаємопов'язаних обчислювальних пристроїв, механічних та цифрових машин, об'єктів, тварин або людей, які мають унікальні ідентифікатори (UID) та здатність передавати дані через мережу без необхідності взаємодії людини з людиною або людини з комп'ютером.`,
  EXP_TEXT4: `Річ в інтернеті речей може бути людиною з імплантом монітора серця, сільськогосподарською твариною з біочіпом, автомобілем з вбудованими датчиками, які попереджають водія про низький тиск у шинах, або будь-яким іншим природним або штучним об'єктом, який може бути призначений IP-адресою та здатний передавати дані через мережу.`,
  EXP_TEXT5:
    'Все більше організацій у різних галузях використовують IoT для підвищення ефективності роботи, кращого розуміння клієнтів для надання покращеного обслуговування клієнтів, покращення прийняття рішень та збільшення вартості бізнесу.',
  EXP_TITLE6: 'Відео стрімінг',
  EXP_TEXT6:
    'Ми пропонуємо широкий спектр послуг з розробки додатків для відео стрімінгу, з великою командою фахівців, готових надати результати. Залежно від ваших потреб, ми можемо створити швидко працюючі платформи для живого стрімінгу або послуги відео на вимогу. Ці додатки не тільки зручні (як для користувачів, так і для адміністраторів), але й надійні та здатні витримувати велике навантаження.',

  EXP_TITLE7: 'Планувальник маршрутів для логістики з кількома зупинками',
  EXP_TEXT7: `Ми розробляємо інноваційні логістичні рішення, що відповідають унікальним потребам комерційних електромобілів. Наша передова система планування маршрутів дозволяє бізнесу оптимізувати маршрути, інтегрувати графіки зарядки та підвищувати загальну ефективність роботи автопарків.`,

  _HWORK1: 'Як ми працюємо',
  _HWORK2:
    'Не існує ідеї настільки складної, щоб не можна було розробити оптимальне архітектурне рішення. Наша команда досвідчених інженерів React принесе вашому проекту багатий інституційний досвід роботи з React, заснований на нашому реальному досвіді створення різноманітних додатків, від MVP до складних корпоративних додатків.',
  _HWORK3: 'МИ НАДАЄМО',
  _HWORK31: 'найсучасніший стек технологій для Front-end та Backend',
  _HWORK32:
    'найкращі практики, засновані на нашому досвіді роботи з багатьма реальними виробничими проектами',
  _HWORK34: 'готові до виробництва та CI/CD рішення',
  _HWORK35: 'кваліфіковані архітектори для React Native, React та Backend',
  _HWORK36: "професійний зворотний зв'язок щодо існуючого проекту",
  _HWORK37: 'підтримка реалізації проекту від початку до кінця',
  _HWORK4: 'Фокус на тестуванні',
  _HWORK5:
    'Ключова особливість Node.js - це висока якість коду. Це означає, що він стабільний, передбачуваний та виконує свою мету. Оскільки Node.js може бути як динамічно типізованою мовою, так і сильно типізованою мовою, якість підтримується на необхідному рівні завдяки автоматизованому тестуванню. Екосистема Node.js надає кілька сучасних фреймворків для автоматизованого тестування. Вони були відточені та перевірені в боях протягом багатьох років найвидатнішими розробниками в спільноті',
  _HWORK6: 'Хочете дізнатися більше прикладів?',

  _MOBILITY_DESCRIPTION:
    'Тут ви можете знайти наш розділ експертизи в розробці програмного забезпечення для електромобільності',
  _MOBILITY_KEYWORDS:
    'рішення для електромобільності на react, демо ocpp на react, система управління ocpp на react, панель управління ocpp, система білінгу ocpp, система управління зарядкою ocpp',
  _MOBILITY_HEAD_H1: 'Рішення для електромобільності',
  _MOBILITY_HEAD_TEXT:
    'Ефективні рішення для обох сторін індустрії електромобільності - операторів EMSP та CPO. Гарантований безперебійний досвід завдяки впровадженню відкритих стандартів, розробці додатків та платформ, консультуванню в галузі та багато іншого.',
  _MOBILITY_BACK: 'Назад до розділу експертизи',
  _MOBILITY_TITLE1: 'Продукти майбутнього для наступного покоління',
  _MOBILITY_TEXT1: `Через програмне забезпечення ми надаємо найгнучкіші програмні рішення для інфраструктури EV. Керуйте будь-яким зарядним пристроєм OCPP, заряджайте будь-який електромобіль та приймайте всі основні методи оплати.`,
  _MOBILITY_TEXT2: `Наші інноваційні продукти формують майбутнє автомобільного дизайну. Ми базуємо складні автомобільні дизайни на перспективних рішеннях. Як давній та надійний партнер автомобільної індустрії, voestalpine виробляє інноваційні продукти від дизайну до прототипу та від виготовлення інструментів до серійного виробництва. Кожен компонент, будь то окрема деталь або складний модуль, відповідає необхідним вимогам безпеки та зниження ваги. Таким чином, кожен інноваційний компонент робить внесок у світле майбутнє.`,
  _MOBILITY_TITLE2: `Давайте почнемо`,
  _MOBILITY_TEXT3: `Зарядка EV не повинна бути складною. Ми можемо надати ваше комплексне рішення для інфраструктури EV з багатьма налаштуваннями та гнучким ціноутворенням для ваших клієнтів.`,
  _MOBILITY_TITLE4: `Управління зарядними пристроями EV`,
  _MOBILITY_TEXT4: `Підключайте та керуйте будь-яким зарядним пристроєм OCPP. Ви можете створювати власні програмні плани, починаючи з $0. Безкоштовні години, знижки на різні часові діапазони дозволяють додавати гейміфікацію та маркетингові функції до ваших станцій (підтримка протоколів OCCP та найвідоміших виробників зарядних пристроїв для EV).`,
  _MOBILITY_TITLE5: `Інтеграції управління EV з CRM`,
  _MOBILITY_TEXT5: `Ми можемо легко інтегрувати наші системи управління зарядними пристроями до вашої системи CRM, сервісів сповіщень та реалізувати будь-яку складність інтеграції з програмним забезпеченням третіх сторін.`,
  _MOBILITY_MORE_BUTTON: 'Більше про зарядку EV',
  _MOBILITY_TITLE6: `Обліковий запис водія EV`,
  _MOBILITY_TEXT6: `Жодних членських внесків або депозитів у гаманці не потрібно, ні зараз, ні в майбутньому.`,

  _CHARGING_TITLE: 'Програмне забезпечення для заряджання EV (OCPP)',
  _CHARGING_DESCRIPTION:
    'Максимізуйте прибутковість вашої зарядної мережі та оцініть наше передове програмне забезпечення для зарядних станцій EV для операторів та постачальників послуг.',
  _CHARGING_TITLE1: 'Програмне забезпечення для галузі заряджання EV',
  _CHARGING_TEXT1:
    'Використовуйте наше програмне забезпечення для заряджання EV з білим маркуванням, щоб легко керувати вашою інфраструктурою заряджання EV. Усі зарядні станції адмініструються через багаторівневий вебпортал із розподілом ролей і відповідальності – ви вирішуєте, хто може отримати доступ до системи. Ми пропонуємо як аутсорсингові, так і повністю самокеровані пакети нашого програмного забезпечення для керування заряджанням EV, які відповідають вашим бізнес-потребам.',
  _CHARGING_TITLE2: 'Керування заряджанням стало простішим',
  _CHARGING_TEXT2:
    'Програмне забезпечення для керування заряджанням дозволяє відстежувати, керувати та оптимізувати заряджання електромобілів і отримувати додатковий дохід. Від доступу до розширеної аналітики через наш бізнес-портал до керування заряджанням у дорозі через наш додаток Charge – перехід на електромобілі ще ніколи не був таким простим.',
  _CHARGING_TITLE3: 'Оптимальне використання мережі з EV Smart Charging',
  _CHARGING_TEXT3:
    'Якщо багато електромобілів заряджаються одночасно в одній мережі, є ризик перевантаження енергетичної системи. Завдяки Smart Charging ви можете збільшити кількість електромобілів, які можуть заряджатися одночасно, без додаткових інвестицій у мережу.',
  _CHARGING_TITLE4: 'Керування зарядними пристроями EV',
  _CHARGING_TEXT4:
    'Підключайте та керуйте будь-якими зарядними пристроями OCPP. Ви можете створювати власні плани програмного забезпечення від $0. Безкоштовні години, знижки в різний час дозволяють додати гейміфікацію та маркетингові функції до ваших станцій (підтримка протоколів OCCP та найвідоміших виробників зарядок EV).',
  _CHARGING_TITLE5: 'Керування інфраструктурою',
  _CHARGING_TEXT51:
    'Керуйте зарядними станціями, пристроями та з’єднаннями EV.',
  _CHARGING_TEXT52: 'Моніторьте сесії, станції та зарядні пристрої.',
  _CHARGING_TEXT53: 'Розумне енергоспоживання з балансуванням навантаження.',
  _CHARGING_TEXT54: 'Вирішуйте проблеми.',
  _CHARGING_TEXT55:
    'Виконуйте дистанційні команди (перезавантаження, оновлення прошивки тощо).',
  _CHARGING_TEXT56:
    'Панель приладів із даними в реальному часі та Google Maps.',
  _CHARGING_TEXT57:
    'Гнучкість апаратного забезпечення завдяки Open Charge Point Protocol – OCPP.',
  _CHARGING_TITLE6: 'Рішення API-платформи',
  _CHARGING_TEXT6:
    'Налаштуйте свій досвід заряджання EV за допомогою нашої інноваційної відкритої платформи для заряджання та послуг. Наша API-платформа дозволяє операторам створювати власний досвід користувача через потужний API-шлюз, який дозволяє розробляти власний додаток для водіїв і використовувати портал управління для керування вашими станціями. Незалежно від того, чи хочете ви інтегрувати свій поточний мобільний додаток, прив’язати заряджання до винагород чи членства, або скористатися іншими послугами eMobility – наша API-платформа підтримує ваші потреби. Вона також ідеально підходить для інтеграції у флоти, енергоменеджмент та телематику транспортних засобів.',

  _DASHBOARD_TITLE: 'Розробка дашбордів (React)',
  _DASHBOARD_DESCRIPTION:
    'Максимізуйте прибутковість вашої мережі зарядних станцій і скористайтеся нашою передовою програмою для операторів та постачальників послуг зарядних станцій EV.',
  _DASHBOARD_TITLE1: 'Послуги з розробки React',
  _DASHBOARD_TEXT1:
    'Хочете створити динамічний та інтерактивний веб-додаток? Більше не шукайте! Ми надаємо послуги з розробки ReactJS для задоволення всіх ваших потреб у розробці. ReactJS-додатки, які ми створюємо, є високофункціональними та мають нативне відчуття для ваших мобільних додатків. Звісно, вони легкі, швидкі та чуйні.',
  _DASHBOARD_BACK: 'Назад до розділу експертизи',
  _DASHBOARD_TITLE2:
    'Рішення для підприємств. Максимальна продуктивність. Якість коду.',
  _DASHBOARD_TEXT21:
    'У нас є команда досвідчених React-розробників, які використовують гнучкість та динамічність React JS для створення веб-додатків з найвищим рівнем безпеки. Наші послуги допомагають створювати веб-додатки з високою швидкістю, простотою та надійністю.',
  _DASHBOARD_TEXT22:
    'React JS є основною платформою для створення інтерфейсів користувача. Всі наші команди мають великий досвід роботи з React і успішно реалізували десятки проєктів.',
  _DASHBOARD_TEXT23:
    'Доступ до досвідчених React.js розробників з історією успіху в розробці масштабних веб-додатків. Високі стандарти якості, підтверджені понад 200 проєктами.',
  _DASHBOARD_BUTTON: 'Показати приклади',
  _DASHBOARD_TITLE3: 'Розпочніть завтра',
  _DASHBOARD_TEXT3:
    'Вам не потрібно створювати внутрішню організацію. Найміть нашу команду розробників для проєкту та замініть нас внутрішнім персоналом згодом.',
  _DASHBOARD_TITLE4: 'Розробка React.js з нуля',
  _DASHBOARD_TEXT4:
    'Ви можете передати нам розробку React, і ми створимо проєкт з нуля для вас.',
  _DASHBOARD_TITLE5: 'Міграція на React',
  _DASHBOARD_TEXT5:
    'Наші експерти виконають високоякісну міграцію на React з будь-якої іншої платформи.',
  _DASHBOARD_TITLE6: 'Команда розробників React.js',
  _DASHBOARD_TEXT6:
    'Найміть команду React.js-розробників, які відповідатимуть вашим потребам у масштабі. Вони також здатні переписати погано написаний код іншої компанії для відповідності галузевим стандартам.',
  _DASHBOARD_TITLE7: 'Розробка додатків React Native',
  _DASHBOARD_TEXT7:
    'Скористайтеся всіма перевагами провідної платформи для розробки кросплатформених мобільних додатків.',

  _AI_TITLE:
    'Веб-розробка нового покоління: Швидкі та якісні рішення з Node.js, React.js та AI',
  _AI_TEXT:
    'У сучасному цифровому світі веб-розробка стала основою успішного бізнесу. Ми спеціалізуємося на створенні динамічних, масштабованих та надійних веб-рішень, які забезпечують найвищу продуктивність та зручність. Наші інструменти — Node.js і React.js, а також технології штучного інтелекту (AI), які дозволяють створювати рішення, повністю відповідні вашим бізнес-потребам.',
  _AI_KEYWORDS:
    'веб-розробка, створення лендингів, node.js розробка, react.js розробка, AI розробка, веб-застосунки, створення веб-сайтів, розробка під ключ, модернізація веб-застосунків, інтеграція штучного інтелекту, інтерактивні веб-рішення',
  _AI_TITLE1: 'Наш підхід:',
  _AI_SECTION1: '1. Швидкість та ефективність',
  _AI_TEXT1:
    'Ми використовуємо Node.js для розробки серверної частини, забезпечуючи блискавичну обробку даних, і React.js — для створення інтерактивних користувацьких інтерфейсів. Це поєднання дозволяє запускати проєкти швидше та з мінімальними витратами.',
  _AI_SECTION2: '2. Якість та надійність',
  _AI_TEXT2:
    'Ми прагнемо до найвищої якості на всіх етапах розробки. Наші спеціалісти уважно стежать за кожним кроком проєкту, щоб забезпечити його надійність та стабільність.',
  _AI_SECTION3: '3. AI-інтеграція',
  _AI_TEXT3:
    'Ми використовуємо технології штучного інтелекту для створення розумних та інноваційних рішень, які допомагають оптимізувати бізнес-процеси та підвищують ефективність вашого бізнесу. Додайте інновацію до вашого проєкту! Ми інтегруємо можливості штучного інтелекту, такі як:',
  _AI_TEXT31: 'Автоматизація процесів',
  _AI_TEXT32: 'Персоналізація користувацького досвіду',
  _AI_TEXT33: 'Аналіз великих даних для прийняття рішень',

  _AI_TITLE2: 'Наші послуги:',
  _AI_SECTION4: 'Створення веб-застосунків з нуля',
  _AI_TEXT4:
    'Повний цикл розробки — від ідеї до робочого продукту. Ми розробляємо потужні веб-застосунки, які ефективно вирішують завдання вашого бізнесу.',
  _AI_SECTION5: 'Міграція на сучасні платформи',
  _AI_TEXT5:
    'Перенесення застарілих застосунків на сучасні технології Node.js і React.js для підвищення продуктивності та покращення інтерфейсу.',
  _AI_SECTION6: 'Інтеграція AI у наявні системи',
  _AI_TEXT6:
    'Ми допомагаємо впровадити машинне навчання, чат-ботів та інші AI-рішення у ваш проєкт.',

  _AI_TITLE7: 'Готові почати?',
  _AI_TEXT7:
    'Напишіть нам сьогодні, щоб обговорити ваш проєкт і дізнатися, як ми можемо допомогти вашому бізнесу зростати за допомогою сучасних технологій Node.js, React.js та AI.'
};

export default translate;
