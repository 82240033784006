import React from 'react';
import { Helmet } from 'react-helmet';
// import { Link } from 'react-router-dom';
// import * as Scroll from 'react-scroll';
import { useSelector } from 'react-redux';

// import Button from '../../components/Button';
import Section from '../../components/Section';
// import Button from '../../components/Button';
import styles from './ai.module.scss';
import metaValues from '../../../config';
import reactIllustration from '../../assets/img/react-application.png';
import translation from '../../translate';

const AISolutions = () => {
  const { defaultTitle } = metaValues;
  const { language } = useSelector(({ common }: any) => common);

  return (
    <div className={styles.body}>
      <Helmet>
        <title>
          {translation('_AI_TITLE', language)} - {defaultTitle}
        </title>
        <meta name="description" content={translation('_AI_TEXT', language)} />
        <meta name="keywords" content={translation('_AI_KEYWORDS', language)} />
        <meta property="og:title" content={defaultTitle} />
        <meta property="og:image" content="/public/react-application.png" />
      </Helmet>

      <Section className={styles.content}>
        <h1>{translation('_AI_TITLE', language)}</h1>

        <img
          src={reactIllustration}
          className={styles.reactImage}
          alt="React applications development"
        />

        <p>{translation('_AI_TEXT', language)}</p>

        <h2>{translation('_AI_TITLE1', language)}</h2>
        <h4>{translation('_AI_SECTION1', language)}</h4>
        <p>{translation('_AI_TEXT1', language)}</p>

        <h4>{translation('_AI_SECTION2', language)}</h4>
        <p>{translation('_AI_TEXT2', language)}</p>

        <h4>{translation('_AI_SECTION3', language)}</h4>
        <p>{translation('_AI_TEXT3', language)}</p>
        <ul>
          <li>{translation('_AI_TEXT31', language)}</li>
          <li>{translation('_AI_TEXT32', language)}</li>
          <li>{translation('_AI_TEXT33', language)}</li>
        </ul>

        <h2>{translation('_AI_TITLE2', language)}</h2>
        <h4>{translation('_AI_SECTION4', language)}</h4>
        <p>{translation('_AI_TEXT4', language)}</p>

        <h4>{translation('_AI_SECTION5', language)}</h4>
        <p>{translation('_AI_TEXT5', language)}</p>

        <h4>{translation('_AI_SECTION6', language)}</h4>
        <p>{translation('_AI_TEXT6', language)}</p>

        <h2>{translation('_AI_TITLE7', language)}</h2>
        <p>{translation('_AI_TEXT7', language)}</p>
      </Section>
    </div>
  );
};

export default AISolutions;
