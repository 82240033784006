const translate = {
  _SEO_TITLE1: 'Hire the world-class developers for your business',
  _SEO_TITLE2:
    'We provide React.js development services, helping businesses and startups to build their dream projects. We are always looking for the best solutions for our clients business. One such solution is using React.js to develop online services and web applications.',
  _SEO_TITLE3:
    'React application development, Nodejs development, react applications, server-side rendering, SSR apps, SSR react app',
  _HOME_LINK1: 'Expertise',
  _HOME_LINK2: 'How we work',
  _HOME_LINK3: 'AI solutions',
  _HOME_CONTACT: 'Contact us',
  _HOME_SECTION3: 'Our expertise',

  EXP_DESCRIPTION:
    'Professional React development — creating fast, responsive and scalable web applications. Modern technologies, high-level UX/UI and optimization for your business goals.',
  EXT_T1: 'OUR EXPERTISE',
  EXT_TT:
    'Our team can provide a wide range of mobile, web, desktop and enterprise software technology expertise across all major development environments.',

  WANT_BUTTON: 'Want to know more?',
  EXP_TITLE1: 'E-Mobility solutions',
  EXP_TEXT1:
    'Efficient solutions for both sides of the e-mobility industry - EMSP and CPO operators. Guaranteed seamless experience through Open Standards implementation, apps & platforms development, domain consulting and many more.',

  EXP_TITLE2: 'Any type of React applications',
  EXP_TEXT2:
    'Imagine that you need to develop a web application from scratch to monitor and analyze raw data. For example, you own an average size online store. You are not satisfied with the existing solutions for data analytics and you want to manage what components will be in your admin panel by yourself. It is also very important for you to work with raw data and build analytical charts yourself.',

  EXP_TITLE3: 'Data flows for IOT projects',
  EXP_TEXT3:
    'The internet of things, or IoT, is a system of interrelated computing devices, mechanical and digital machines, objects, animals or people that are provided with unique identifiers (UIDs) and the ability to transfer data over a network without requiring human-to-human or human-to-computer interaction.',
  EXP_TEXT4:
    'A thing in the internet of things can be a person with a heart monitor implant, a farm animal with a biochip transponder, an automobile that has built-in sensors to alert the driver when tire pressure is low or any other natural or man-made object that can be assigned an Internet Protocol (IP) address and is able to transfer data over a network.',
  EXP_TEXT5:
    'Increasingly, organizations in a variety of industries are using IoT to operate more efficiently, better understand customers to deliver enhanced customer service, improve decision-making and increase the value of the business.',
  EXP_TITLE6: 'Video streaming',
  EXP_TEXT6:
    'We are offers a range of video streaming app development services, with a large team of specialists available to deliver results. Depending on your needs, we can build fast-running live streaming platforms or video-on-demand services. Not only are these apps built for utmost convenience (both in the user experience and admin management), but they are also robust and expertly handle the strain of large traffic flows.',

  EXP_TITLE7: 'TRIP PLANNER FOR MULTI-STOP LOGISTICS',
  EXP_TEXT7: `At the forefront of sustainable innovation, we develop cutting-edge logistics solutions tailored to the unique needs of commercial electric vehicles (EVs). Our advanced route planning system empowers businesses to optimize routes, seamlessly integrate charging schedules, and enhance overall operational efficiency for electric vehicle fleets.`,

  _HWORK1: 'How we work',
  _HWORK2:
    'There is no idea so complex, that the optimal architectural solution cannot be developed. Our team of experienced React Engineers will bring your project a wealth of institutional knowledge about React, based on our real-world experience building a variety of applications, from MVP to complex enterprise-grade apps.',
  _HWORK3: 'WE PROVIDE',
  _HWORK31: 'the most modern stack of technologies for Front-end and Backend',
  _HWORK32:
    'best practices based on our experience with many real production projects',
  _HWORK34: 'production and CI/CD ready solutions',
  _HWORK35: 'skilled Architects for React Native, React and Backend',
  _HWORK36: 'professional feedback on an existing project',
  _HWORK37: 'project implementation support from start to finish',
  _HWORK4: 'Focus on Testing',
  _HWORK5:
    'The key feature about Node.js is the high quality of code. Which in turn means that it is stable, predictable and accomplishes its goal. Because Node.js can be and dynamically-typed language and strong typed language, the quality it kept at the required level by exercising the automated testing. Node.js ecosystem provides several state of the art automated testing frameworks. They have been polished and battle-tested for years by the most prominent developers in the community',
  _HWORK6: 'Want to know more examples?',

  _MOBILITY_DESCRIPTION:
    'There you can find our E-Mobility expertise section in software development',
  _MOBILITY_KEYWORDS:
    'react emobility solutions, react ocpp demo, react ocpp management system, ocpp dashboard, ocpp billing system, ocpp charging management',
  _MOBILITY_HEAD_H1: 'E-Mobility solutions',
  _MOBILITY_HEAD_TEXT:
    'Efficient solutions for both sides of the e-mobility industry - EMSP and CPO operators. Guaranteed seamless experience through Open Standards implementation, apps &amp; platforms development, domain consulting and many more.',
  _MOBILITY_BACK: 'Back to expertise section',
  _MOBILITY_TITLE1: 'Products of the future for the next generation',
  _MOBILITY_TEXT1: `Through software, we bring the industry most flexible EV infrastructure software solutions. Manage any OCPP charger, charge any electric car, and accept every major payment method.`,
  _MOBILITY_TEXT2: `Our innovative products are shaping the future of automotive design. We base complex automotive designs on forward-looking solutions. As a long-standing and reliable partner to the automotive industry, voestalpine manufactures innovative products from design to prototype and from toolmaking to serial production. Each component, whether an individual part or a complex module, meets the necessary safety and weight reduction requirements. This way, each innovative component makes a contribution to a brighter future.`,
  _MOBILITY_TITLE2: `Let's get started`,
  _MOBILITY_TEXT3: `EV charging doesn't have to be complicated. We can bring your end-to-end EV infrastructure solution with a lot of custom settings and flexible pricing for your customers.`,
  _MOBILITY_TITLE4: `EV charger management`,
  _MOBILITY_TEXT4: `Connect & control any OCPP EV charger. You can build own software plans starting at $0. Free hours, discounts on different timeranges allows you to add gamification and marketing features to yours stations (support OCCP protocols and most famous EV-charging manufacturers).`,
  _MOBILITY_TITLE5: `EV management integrations with CRM`,
  _MOBILITY_TEXT5: `We can easy integrate our management systems for chargers to your CRM system, notification services and implement any complexity integration with third-parties software.`,
  _MOBILITY_MORE_BUTTON: 'More about EV charging',
  _MOBILITY_TITLE6: `EV driver account`,
  _MOBILITY_TEXT6: `No membership fees or wallet deposits required, now or ever.`,

  _CHARGING_TITLE: 'EV charging software (OCPP)',
  _CHARGING_DESCRIPTION:
    'Maximize the profitability of your charging network and experience our state-of-the-art EV charging station software for operators and service providers.',
  _CHARGING_TITLE1: 'Software for the EV charging industry',
  _CHARGING_TEXT1:
    'Use our white-label EV charging software to easily manage your EV charging infrastructure. All charge points are administered on a multi-level web portal with separated roles and responsibilities – you can decide who can access the system. We offer both outsourced as well as fully self-managed packages of our EV charging management software, fitting to your business needs.',
  _CHARGING_TITLE2: 'Charging management made easy',
  _CHARGING_TEXT2:
    'Charging management software lets you track, manage, and optimize electric vehicle charging and earn additional revenue. From accessing advanced insights via our Business Portal to managing charging on-the-go via our Charge app, going electric has never been easier.',
  _CHARGING_TITLE3: 'Make optimal use of the grid with EV smart charging',
  _CHARGING_TEXT3:
    'If lots of electric vehicles would charge at the same time, on the same grid, there is a chance that the energy network will overload. With smart charging, you are able to increase the number of electric vehicles that can be charged simultaneously without making additional investments in the grid.',
  _CHARGING_TITLE4: 'EV charger management',
  _CHARGING_TEXT4:
    'Connect & control any OCPP EV charger. You can build own software plans starting at $0. Free hours, discounts on different timeranges allows you to add gamification and marketing features to yours stations (support OCCP protocols and most famous EV-charging manufacturers).',
  _CHARGING_TITLE5: 'Infrastructure Management',
  _CHARGING_TEXT51: 'Manage EV charging stations, chargers, and connectors.',
  _CHARGING_TEXT52: 'Monitor sessions, stations, and chargers.',
  _CHARGING_TEXT53: 'Smart energy management with load balancing.',
  _CHARGING_TEXT54: 'Troubleshoot problems.',
  _CHARGING_TEXT55: 'Apply remote commands (reboot, firmware update, etc.).',
  _CHARGING_TEXT56: 'Dashboard with real-time data and Google maps.',
  _CHARGING_TEXT57:
    'Hardware flexibility through Open Charge Point Protocol – OCPP.',
  _CHARGING_TITLE6: 'API Platform Solution',
  _CHARGING_TEXT6:
    'Customize your EV charging experience with our innovative and open charging network platform of software and services. Our API Platform empowers operators to create their own user experience through a powerful API gateway that allows you to develop your own driver application and capitalize on management portal to manage your stations. Whether you want to integrate your current mobile app, tie charging into rewards or memberships, or have other eMobility services, our API Platform supports your needs. It’s also perfect for integration into fleet operations, energy management solutions and vehicle telematics systems.',

  _DASHBOARD_TITLE: 'Dashboard development (React)',
  _DASHBOARD_DESCRIPTION:
    'Maximize the profitability of your charging network and experience our state-of-the-art EV charging station software for operators and service providers.',
  _DASHBOARD_TITLE1: 'React Development Services',
  _DASHBOARD_TEXT1:
    'Want a dynamic and interactive single page application to be built? Look no further! We provide ReactJS Development Services to cater to all your development needs. The ReactJS applications we create are highly functional and gives a native feeling to your mobile apps. Needless to say, they are lightweight, quick and responsive.',
  _DASHBOARD_BACK: 'Back to expertise section',
  _DASHBOARD_TITLE2: 'Enterprise solutions. Peak performance. Code quality.',
  _DASHBOARD_TEXT21:
    'We have a dedicated team of React developers who leverage the flexibility and dynamism offered by React JS to develop consumer-facing web applications with the highest levels of security. Our React Development Services help in developing data-driven web applications with great speed, simplicity and robustness.',
  _DASHBOARD_TEXT22:
    'React JS is the primary front-end platform on which we build user interfaces and UI components. All our teams have extensive knowledge and experience with React development and have worked on dozens of projects.',
  _DASHBOARD_TEXT23:
    'Access only experienced React.js developers with a track record in large-scale web development. Quality standards defined by +200 projects.',
  _DASHBOARD_BUTTON: 'Show me examples',
  _DASHBOARD_TITLE3: 'Start Tomorrow',
  _DASHBOARD_TEXT3:
    'You don’t need to build your internal organisation. Hire our team of developers for the project and replace us with internal staff later.',
  _DASHBOARD_TITLE4: 'React.js development from scratch',
  _DASHBOARD_TEXT4:
    'You can outsource React development to us, and we’ll build a project from scratch for you.',
  _DASHBOARD_TITLE5: 'Migration to React',
  _DASHBOARD_TEXT5:
    'Our experts will perform high-quality migration to React from any other frontend framework.',
  _DASHBOARD_TITLE6: 'Dedicated React.js development team',
  _DASHBOARD_TEXT6:
    'Hire dedicated React.js developers to fit your needs at scale. They are also skilled enough to refactor the poorly written code by another React.js development company to meet industry standards.',
  _DASHBOARD_TITLE7: 'React Native app development',
  _DASHBOARD_TEXT7:
    'Experience the full power of the top cross-platform mobile app development framework.',

  _AI_TITLE:
    'Next-Generation Web Development: Fast and High-Quality Solutions with Node.js, React.js, and AI',
  _AI_TEXT:
    "In today's digital world, web development has become the foundation of a successful business. We specialize in creating dynamic, scalable, and reliable web solutions that ensure high performance and usability. Our tools — Node.js, React.js, and artificial intelligence (AI) technologies — enable us to create solutions tailored to your business needs.",
  _AI_KEYWORDS:
    'web development, landing page creation, Node.js development, React.js development, AI development, web applications, website creation, turnkey development, web application modernization, artificial intelligence integration, interactive web solutions',
  _AI_TITLE1: 'Our Approach:',
  _AI_SECTION1: '1. Speed and Efficiency',
  _AI_TEXT1:
    'We use Node.js for backend development to ensure lightning-fast data processing, and React.js for building interactive user interfaces. This combination allows for faster project delivery with minimal costs.',
  _AI_SECTION2: '2. Quality and Reliability',
  _AI_TEXT2:
    'We strive for the highest quality at every stage of development. Our experts carefully oversee every step of the project to ensure its reliability and stability.',
  _AI_SECTION3: '3. AI Integration',
  _AI_TEXT3:
    'We use artificial intelligence technologies to create smart and innovative solutions that optimize business processes and improve efficiency. Add innovation to your project! We integrate AI capabilities such as:',
  _AI_TEXT31: 'Process automation',
  _AI_TEXT32: 'Personalized user experience',
  _AI_TEXT33: 'Big data analysis for decision-making',
  _AI_TITLE2: 'Our Services:',
  _AI_SECTION4: 'Building Web Applications from Scratch',
  _AI_TEXT4:
    'A full development cycle — from idea to finished product. We develop powerful web applications that effectively solve your business challenges.',
  _AI_SECTION5: 'Migration to Modern Platforms',
  _AI_TEXT5:
    'Migrating outdated applications to modern technologies like Node.js and React.js to improve performance and interface.',
  _AI_SECTION6: 'AI Integration into Existing Systems',
  _AI_TEXT6:
    'We help incorporate machine learning, chatbots, and other AI solutions into your project.',
  _AI_TITLE7: 'Ready to Start?',
  _AI_TEXT7:
    'Contact us today to discuss your project and learn how we can help your business grow with modern technologies like Node.js, React.js, and AI.',

  _ROUTE_TITLE:
    'Advanced Electric Vehicle Trip Planner for Multi-Stop Logistics',
  _ROUTE_DESCRIPTION:
    'Discover innovative solutions for commercial electric vehicle logistics. Our multi-stop EV trip planner optimizes routes, integrates charging stops, and ensures seamless fleet operations.',
  _ROUTE_KEYWORDS:
    'electric vehicle logistics, EV trip planner, multi-stop routing, EV charging optimization, fleet management, sustainable logistics, electric vehicle navigation, charging station integration'
};

export default translate;
