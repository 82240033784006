// @flow
import * as React from 'react';
import { Link, useLocation } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { animateScroll as scroll } from 'react-scroll';

import styles from './footer.module.scss';
import { languages, languageTitles } from '../../translate';
import languageChange from '../../store/actions/common';

const scrollOption = {
  duration: 50,
  delay: 10,
  smooth: true
};

const handleScrollClick = () => {
  scroll.scrollToTop(scrollOption);
};

const Footer = (): any => {
  const location = useLocation();
  const dispatch = useDispatch();
  let { pathname } = location;
  const common = useSelector(({ common: comm }: any) => comm);
  const { language, hostname } = common;

  const heart = (
    <span role="img" className={styles.emojii} aria-label="Heart">
      ❤️
    </span>
  );

  if (pathname === '/') {
    pathname = `${hostname}/${language}/`;
  }

  return (
    <div className={styles.footer}>
      <div className={styles.content}>
        <div className={styles.languages}>
          {languages.map(lng => {
            const currLocation = hostname + pathname;

            let langLocation = currLocation.replace(
              `/${language}/`,
              `/${lng}/`
            );

            if (pathname === `/${language}`) {
              langLocation = `${hostname}/${lng}`;
            }

            const current = lng === language;

            if (current) {
              return (
                <span key={lng} className={styles.current}>
                  {languageTitles[lng]}
                </span>
              );
            }

            return (
              <Link
                key={lng}
                to={langLocation}
                className={current ? styles.active : ''}
                onClick={() => {
                  dispatch(languageChange(lng));
                  handleScrollClick();
                }}
              >
                {languageTitles[lng].toUpperCase()}
              </Link>
            );
          })}
        </div>

        <ul className={styles.grid}>
          <ol>
            <h3>Services</h3>
            <li>
              <Link
                to={`${hostname}/${language}/expertise/emobility`}
                onClick={handleScrollClick}
              >
                E-mobility solutions
              </Link>
            </li>
            <li>
              <Link
                to={`${hostname}/${language}/expertise/react-dashboards`}
                onClick={handleScrollClick}
              >
                Custom software development
              </Link>
            </li>
            <li>Dedicated dev teams</li>
            <li>Web Development</li>
            <li>Mobile development</li>
            <li>IT consulting</li>
          </ol>
          <ol>
            <h3>Case Studies</h3>
            <li>True energy</li>
            <li>Ave mobility</li>
            <li>T-Cup</li>
            <li>Grand Vision</li>
            <li>Petroleum Tax Tools</li>
            <li>Concise Software</li>
            <li>Channel Bytes</li>
            <li>Connect your care</li>
          </ol>
          <ol>
            <h3>Technologies</h3>
            <li>Docker</li>
            <li>ReactJS</li>
            <li>React Native</li>
            <li>NodeJS</li>
            <li>Postgres SQL</li>
            <li>MongoDB</li>
            <li>Cloud services</li>
          </ol>
        </ul>
        <div className={styles.title}>
          Made with
          {heart}
          in Kharkiv, Ukraine (с) 2015-2025
        </div>
      </div>
    </div>
  );
};

export default Footer;
