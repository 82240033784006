const translate = {
  _SEO_TITLE1: 'Zatrudnij światowej klasy programistów dla swojej firmy',
  _SEO_TITLE2:
    'Świadczymy usługi programistyczne React.js, aby pomóc firmom i start-upom w tworzeniu ich marzeń. Zawsze poszukujemy najlepszych rozwiązań dla biznesów naszych klientów. Jednym z takich rozwiązań jest wykorzystanie React.js do tworzenia usług online i aplikacji internetowych.',
  _SEO_TITLE3:
    'Reaguj tworzenie aplikacji, rozwój Nodejs, reagują aplikacje, renderowanie po stronie serwera, aplikacje SSR, aplikacja SSR reagująca',
  _HOME_LINK1: 'Ekspertyza',
  _HOME_LINK2: 'Jak my pracujemy',
  _HOME_LINK3: 'AI Rozwiązania',
  _HOME_CONTACT: 'Skontaktuj',
  _HOME_SECTION3: 'Nasze doświadczenie',

  EXP_DESCRIPTION:
    'Profesjonalne tworzenie aplikacji w React — szybkie, responsywne i skalowalne aplikacje internetowe. Nowoczesne technologie, wysoki poziom UX/UI i optymalizacja pod cele Twojego biznesu.',
  EXT_T1: 'NASZA EKSPERTYZA',
  EXT_TT:
    'Nasz zespół może zapewnić szeroką wiedzę specjalistyczną w zakresie technologii oprogramowania mobilnego, internetowego, stacjonarnego i korporacyjnego we wszystkich głównych środowiskach programistycznych.',

  WANT_BUTTON: 'Chcieć wiedzieć więcej?',
  EXP_TITLE1: 'Rozwiązania z zakresu elektromobilności',
  EXP_TEXT1:
    'Efektywne rozwiązania dla obu stron branży e-mobility – operatorów EMSP i CPO. Gwarantowane bezproblemowe doświadczenie dzięki wdrażaniu otwartych standardów, tworzeniu aplikacji i platform, doradztwu w zakresie domen i wielu innym.',

  EXP_TITLE2: 'Dowolny typ aplikacji React',
  EXP_TEXT2:
    'Wyobraź sobie, że musisz od podstaw stworzyć aplikację internetową, która będzie monitorować i analizować surowe dane. Na przykład jesteś właścicielem średniej wielkości sklepu internetowego. Nie zadowalają Cię dotychczasowe rozwiązania do analityki danych i chcesz samodzielnie zarządzać, jakie komponenty znajdą się w Twoim panelu administracyjnym. Bardzo ważna jest także Twoja praca z surowymi danymi i samodzielne budowanie wykresów analitycznych.',

  EXP_TITLE3: 'Przepływy danych dla projektów IOT',
  EXP_TEXT3:
    'Internet rzeczy, czyli IoT, to system wzajemnie powiązanych urządzeń obliczeniowych, maszyn mechanicznych i cyfrowych, obiektów, zwierząt lub osób, które posiadają unikalne identyfikatory (UID) i możliwość przesyłania danych w sieci bez konieczności kontaktu człowieka z człowiekiem. interakcji człowiek lub człowiek-komputer.',
  EXP_TEXT4:
    'Rzeczą w Internecie rzeczy może być osoba z implantem monitorującym pracę serca, zwierzę hodowlane z transponderem biochipu, samochód z wbudowanymi czujnikami ostrzegającymi kierowcę o niskim ciśnieniu w oponach lub jakikolwiek inny naturalny lub sztuczny przypadek obiekt, któremu można przypisać adres protokołu internetowego (IP) i który może przesyłać dane w sieci.',
  EXP_TEXT5:
    'Coraz częściej organizacje z różnych branż korzystają z Internetu Rzeczy, aby działać wydajniej, lepiej rozumieć klientów, co pozwala im zapewniać lepszą obsługę, usprawniać proces podejmowania decyzji i zwiększać wartość firmy.',
  EXP_TITLE6: 'Obraz na żywo',
  EXP_TEXT6:
    'Oferujemy szereg usług tworzenia aplikacji do strumieniowego przesyłania wideo, a duży zespół specjalistów jest dostępny, aby zapewnić wyniki. W zależności od Twoich potrzeb możemy zbudować szybko działające platformy do transmisji na żywo lub usługi wideo na żądanie. Aplikacje te są nie tylko stworzone z myślą o maksymalnej wygodzie (zarówno pod względem komfortu użytkowania, jak i zarządzania administracyjnego), ale są również niezawodne i doskonale radzą sobie z obciążeniem związanym z dużym przepływem ruchu.',

  EXP_TITLE7: 'Planista tras dla logistyki z wieloma przystankami',
  EXP_TEXT7: `Jesteśmy na czele innowacji zrównoważonych, opracowując nowoczesne rozwiązania logistyczne dostosowane do unikalnych potrzeb komercyjnych pojazdów elektrycznych. Nasz zaawansowany system planowania tras umożliwia firmom optymalizację tras, bezproblemową integrację harmonogramów ładowania oraz zwiększenie efektywności operacyjnej flot pojazdów elektrycznych.`,

  _HWORK1: 'Jak pracujemy',
  _HWORK2:
    'Nie ma pomysłu tak złożonego, aby nie można było opracować optymalnego rozwiązania architektonicznego. Nasz zespół doświadczonych inżynierów React wniesie do Twojego projektu bogatą wiedzę instytucjonalną na temat React, opartą na naszym rzeczywistym doświadczeniu w tworzeniu różnorodnych aplikacji, od MVP po złożone aplikacje klasy korporacyjnej.',
  _HWORK3: 'Zapewniamy',
  _HWORK31: 'najnowocześniejszy stos technologii dla Front-endu i Backendu',
  _HWORK32:
    'Dobre praktyki oparte na naszym doświadczeniu z wieloma realnymi projektami produkcyjnymi',
  _HWORK34: 'Rozwiązania gotowe do produkcji i CI/CD',
  _HWORK35: 'wykwalifikowani architekci React Native, React i Backend',
  _HWORK36: 'profesjonalna opinia na temat istniejącego projektu',
  _HWORK37: 'wsparcie realizacji projektu od początku do końca',
  _HWORK4: 'Skoncentruj się na testowaniu',
  _HWORK5:
    'Kluczową cechą Node.js jest wysoka jakość kodu. Co z kolei oznacza, że jest stabilny, przewidywalny i realizuje swój cel. Ponieważ Node.js może być językiem dynamicznie typowanym i mocnym językiem typowanym, jakość utrzymywała na wymaganym poziomie poprzez wykonywanie automatycznych testów. Ekosystem Node.js zapewnia kilka najnowocześniejszych platform do automatycznego testowania. Od lat są dopracowywane i testowane w boju przez najwybitniejszych programistów w społeczności',
  _HWORK6: 'Chcesz poznać więcej przykładów?',

  // ---------------------------
  _MOBILITY_DESCRIPTION:
    'Nasze oprogramowanie zapewnia najbardziej elastyczne rozwiązania dla infrastruktury ładowania pojazdów elektrycznych. Zarządzaj dowolną ładowarką OCPP, ładuj dowolny samochód elektryczny i akceptuj wszystkie główne metody płatności.',
  _MOBILITY_KEYWORDS:
    'react emobility solutions, react ocpp demo, react ocpp management system, ocpp dashboard, ocpp billing system, ocpp charging management',
  _MOBILITY_HEAD_H1: 'E-Mobility solutions',
  _MOBILITY_HEAD_TEXT:
    'Efficient solutions for both sides of the e-mobility industry - EMSP and CPO operators. Guaranteed seamless experience through Open Standards implementation, apps &amp; platforms development, domain consulting and many more.',
  _MOBILITY_BACK: 'Back to expertise section',
  _MOBILITY_TITLE1: 'Products of the future for the next generation',
  _MOBILITY_TEXT1: `Through software, we bring the industry most flexible EV infrastructure software solutions. Manage any OCPP charger, charge any electric car, and accept every major payment method.`,
  _MOBILITY_TEXT2: `Our innovative products are shaping the future of automotive design. We base complex automotive designs on forward-looking solutions. As a long-standing and reliable partner to the automotive industry, voestalpine manufactures innovative products from design to prototype and from toolmaking to serial production. Each component, whether an individual part or a complex module, meets the necessary safety and weight reduction requirements. This way, each innovative component makes a contribution to a brighter future.`,
  _MOBILITY_TITLE2: `Let's get started`,
  _MOBILITY_TEXT3: `EV charging doesn't have to be complicated. We can bring your end-to-end EV infrastructure solution with a lot of custom settings and flexible pricing for your customers.`,
  _MOBILITY_TITLE4: `EV charger management`,
  _MOBILITY_TEXT4: `Connect & control any OCPP EV charger. You can build own software plans starting at $0. Free hours, discounts on different timeranges allows you to add gamification and marketing features to yours stations (support OCCP protocols and most famous EV-charging manufacturers).`,
  _MOBILITY_TITLE5: `EV management integrations with CRM`,
  _MOBILITY_TEXT5: `We can easy integrate our management systems for chargers to your CRM system, notification services and implement any complexity integration with third-parties software.`,
  _MOBILITY_MORE_BUTTON: 'More about EV charging',
  _MOBILITY_TITLE6: `EV driver account`,
  _MOBILITY_TEXT6: `No membership fees or wallet deposits required, now or ever.`,

  _CHARGING_TITLE: 'Oprogramowanie do ładowania EV (OCPP)',
  _CHARGING_DESCRIPTION:
    'Zmaksymalizuj rentowność swojej sieci ładowania i wypróbuj nasze nowoczesne oprogramowanie do stacji ładowania EV dla operatorów i dostawców usług.',
  _CHARGING_TITLE1: 'Oprogramowanie dla branży ładowania EV',
  _CHARGING_TEXT1:
    'Skorzystaj z naszego oprogramowania do ładowania EV z możliwością brandingu, aby łatwo zarządzać swoją infrastrukturą ładowania EV. Wszystkie punkty ładowania są zarządzane w wielopoziomowym portalu internetowym z oddzielnymi rolami i odpowiedzialnościami – to Ty decydujesz, kto ma dostęp do systemu. Oferujemy zarówno pakiety outsourcingowe, jak i w pełni zarządzane we własnym zakresie, dostosowane do Twoich potrzeb biznesowych.',
  _CHARGING_TITLE2: 'Łatwe zarządzanie ładowaniem',
  _CHARGING_TEXT2:
    'Oprogramowanie do zarządzania ładowaniem umożliwia śledzenie, zarządzanie i optymalizację ładowania pojazdów elektrycznych oraz generowanie dodatkowych dochodów. Od dostępu do zaawansowanych analiz za pośrednictwem naszego portalu biznesowego po zarządzanie ładowaniem w podróży za pomocą naszej aplikacji Charge – przejście na elektromobilność nigdy nie było prostsze.',
  _CHARGING_TITLE3: 'Optymalne wykorzystanie sieci dzięki Smart Charging',
  _CHARGING_TEXT3:
    'Jeśli wiele pojazdów elektrycznych ładowałoby się jednocześnie w tej samej sieci, istnieje ryzyko przeciążenia sieci energetycznej. Dzięki Smart Charging możesz zwiększyć liczbę pojazdów elektrycznych ładowanych jednocześnie, bez konieczności dodatkowych inwestycji w sieć.',
  _CHARGING_TITLE4: 'Zarządzanie ładowarkami EV',
  _CHARGING_TEXT4:
    'Podłącz i kontroluj dowolną ładowarkę OCPP EV. Możesz tworzyć własne plany oprogramowania już od 0 $. Darmowe godziny, rabaty w różnych zakresach czasowych umożliwiają dodanie funkcji grywalizacji i marketingu do Twoich stacji (obsługa protokołów OCCP i najbardziej znanych producentów ładowarek EV).',
  _CHARGING_TITLE5: 'Zarządzanie infrastrukturą',
  _CHARGING_TEXT51: 'Zarządzaj stacjami ładowania EV, ładowarkami i złączami.',
  _CHARGING_TEXT52: 'Monitoruj sesje, stacje i ładowarki.',
  _CHARGING_TEXT53: 'Inteligentne zarządzanie energią z balansem obciążenia.',
  _CHARGING_TEXT54: 'Rozwiązuj problemy.',
  _CHARGING_TEXT55:
    'Stosuj zdalne komendy (restart, aktualizacja oprogramowania itp.).',
  _CHARGING_TEXT56: 'Panel z danymi w czasie rzeczywistym i Google Maps.',
  _CHARGING_TEXT57:
    'Elastyczność sprzętowa dzięki Open Charge Point Protocol – OCPP.',
  _CHARGING_TITLE6: 'Rozwiązanie API Platform',
  _CHARGING_TEXT6:
    'Dostosuj swoje doświadczenie ładowania EV dzięki naszej innowacyjnej otwartej platformie sieciowej oprogramowania i usług. Nasza platforma API umożliwia operatorom tworzenie własnych doświadczeń użytkownika za pomocą potężnej bramy API, która pozwala opracować własną aplikację dla kierowców i korzystać z portalu zarządzania do zarządzania stacjami. Niezależnie od tego, czy chcesz zintegrować swoją obecną aplikację mobilną, powiązać ładowanie z nagrodami lub członkostwem, czy korzystać z innych usług eMobility – nasza platforma API spełnia Twoje potrzeby. Jest również idealna do integracji z flotami, zarządzaniem energią i systemami telematyki pojazdów.',

  _DASHBOARD_TITLE: 'Rozwój dashboardów (React)',
  _DASHBOARD_DESCRIPTION:
    'Maksymalizuj zyski swojej sieci ładowania i korzystaj z naszego zaawansowanego oprogramowania do stacji ładowania EV dla operatorów i dostawców usług.',
  _DASHBOARD_TITLE1: 'Usługi tworzenia React',
  _DASHBOARD_TEXT1:
    'Chcesz stworzyć dynamiczną i interaktywną aplikację jednostronicową? Nie szukaj dalej! Oferujemy usługi tworzenia w ReactJS, które spełnią wszystkie Twoje potrzeby programistyczne. Tworzone przez nas aplikacje ReactJS są wysoce funkcjonalne i zapewniają natywne odczucie w aplikacjach mobilnych. Oczywiście są lekkie, szybkie i responsywne.',
  _DASHBOARD_BACK: 'Powrót do sekcji specjalistycznej',
  _DASHBOARD_TITLE2:
    'Rozwiązania korporacyjne. Maksymalna wydajność. Jakość kodu.',
  _DASHBOARD_TEXT21:
    'Mamy dedykowany zespół programistów React, którzy wykorzystują elastyczność i dynamikę oferowaną przez React JS do tworzenia aplikacji internetowych o najwyższym poziomie bezpieczeństwa. Nasze usługi w React pomagają w tworzeniu aplikacji internetowych opartych na danych z dużą szybkością, prostotą i niezawodnością.',
  _DASHBOARD_TEXT22:
    'React JS to główna platforma front-end, na której budujemy interfejsy użytkownika i komponenty UI. Wszystkie nasze zespoły mają szeroką wiedzę i doświadczenie w tworzeniu aplikacji w React oraz realizowały dziesiątki projektów.',
  _DASHBOARD_TEXT23:
    'Dostęp wyłącznie do doświadczonych programistów React.js z udokumentowaną historią sukcesów w tworzeniu dużych aplikacji internetowych. Standardy jakości definiowane przez ponad 200 projektów.',
  _DASHBOARD_BUTTON: 'Pokaż mi przykłady',
  _DASHBOARD_TITLE3: 'Zacznij jutro',
  _DASHBOARD_TEXT3:
    'Nie musisz budować wewnętrznej organizacji. Wynajmij nasz zespół programistów do projektu i zastąp nas personelem wewnętrznym później.',
  _DASHBOARD_TITLE4: 'Tworzenie w React.js od podstaw',
  _DASHBOARD_TEXT4:
    'Możesz zlecić nam rozwój w React, a my stworzymy projekt od podstaw dla Ciebie.',
  _DASHBOARD_TITLE5: 'Migracja do React',
  _DASHBOARD_TEXT5:
    'Nasi eksperci wykonają wysokiej jakości migrację do React z dowolnej innej platformy front-end.',
  _DASHBOARD_TITLE6: 'Dedykowany zespół programistów React.js',
  _DASHBOARD_TEXT6:
    'Zatrudnij dedykowanych programistów React.js, którzy spełnią Twoje potrzeby w dużej skali. Są również wystarczająco kompetentni, aby refaktoryzować źle napisany kod przez inną firmę, aby spełniał standardy branżowe.',
  _DASHBOARD_TITLE7: 'Tworzenie aplikacji React Native',
  _DASHBOARD_TEXT7:
    'Doświadcz pełnej mocy wiodącej platformy do tworzenia aplikacji mobilnych wieloplatformowych.',

  _AI_TITLE:
    'Tworzenie stron internetowych nowej generacji: Szybkie i wysokiej jakości rozwiązania z Node.js, React.js i AI',
  _AI_TEXT:
    'W dzisiejszym cyfrowym świecie tworzenie stron internetowych stało się podstawą udanego biznesu. Specjalizujemy się w tworzeniu dynamicznych, skalowalnych i niezawodnych rozwiązań internetowych, które zapewniają wysoką wydajność i wygodę użytkowania. Nasze narzędzia — Node.js, React.js oraz technologie sztucznej inteligencji (AI) — pozwalają nam dostarczać rozwiązania dostosowane do potrzeb Twojej firmy.',
  _AI_KEYWORDS:
    'tworzenie stron internetowych, tworzenie landing pages, rozwój Node.js, rozwój React.js, rozwój AI, aplikacje internetowe, tworzenie witryn internetowych, rozwój pod klucz, modernizacja aplikacji internetowych, integracja sztucznej inteligencji, interaktywne rozwiązania internetowe',
  _AI_TITLE1: 'Nasze podejście:',
  _AI_SECTION1: '1. Szybkość i efektywność',
  _AI_TEXT1:
    'Używamy Node.js do tworzenia backendu, zapewniając błyskawiczne przetwarzanie danych, oraz React.js do tworzenia interaktywnych interfejsów użytkownika. To połączenie pozwala na szybsze realizowanie projektów przy minimalnych kosztach.',
  _AI_SECTION2: '2. Jakość i niezawodność',
  _AI_TEXT2:
    'Dbamy o najwyższą jakość na każdym etapie tworzenia. Nasi specjaliści dokładnie monitorują każdy krok projektu, aby zapewnić jego niezawodność i stabilność.',
  _AI_SECTION3: '3. Integracja AI',
  _AI_TEXT3:
    'Wykorzystujemy technologie sztucznej inteligencji do tworzenia inteligentnych i innowacyjnych rozwiązań, które optymalizują procesy biznesowe i zwiększają efektywność Twojej firmy. Wznieś swój projekt na wyższy poziom! Integrujemy możliwości AI, takie jak:',
  _AI_TEXT31: 'Automatyzacja procesów',
  _AI_TEXT32: 'Personalizacja doświadczenia użytkownika',
  _AI_TEXT33: 'Analiza big data na potrzeby podejmowania decyzji',
  _AI_TITLE2: 'Nasze usługi:',
  _AI_SECTION4: 'Tworzenie aplikacji internetowych od podstaw',
  _AI_TEXT4:
    'Kompleksowy cykl tworzenia — od pomysłu do gotowego produktu. Tworzymy wydajne aplikacje internetowe, które skutecznie rozwiązują wyzwania Twojej firmy.',
  _AI_SECTION5: 'Migracja na nowoczesne platformy',
  _AI_TEXT5:
    'Przenoszenie przestarzałych aplikacji na nowoczesne technologie, takie jak Node.js i React.js, w celu poprawy wydajności i interfejsu.',
  _AI_SECTION6: 'Integracja AI z istniejącymi systemami',
  _AI_TEXT6:
    'Pomagamy wdrożyć rozwiązania sztucznej inteligencji, takie jak uczenie maszynowe, chatboty i inne, w Twój projekt.',
  _AI_TITLE7: 'Gotowy, aby zacząć?',
  _AI_TEXT7:
    'Skontaktuj się z nami już dziś, aby omówić Twój projekt i dowiedzieć się, jak możemy pomóc Twojej firmie rozwijać się dzięki nowoczesnym technologiom, takim jak Node.js, React.js i AI.'
};

export default translate;
