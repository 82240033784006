import React, { memo } from 'react';
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';

// import Button from '../../components/Button';
import Section from '../../components/Section';
import styles from './ev-charging.module.scss';
import chargeStation from '../../assets/img/expertise/ev-charger-ultra.png';
import metaValues from '../../../config';
import translation from '../../translate';

const EVCharging = () => {
  const { defaultTitle } = metaValues;
  const { language } = useSelector(({ common }: any) => common);

  return (
    <div className={styles.body}>
      <Helmet>
        <title>
          {translation('_CHARGING_TITLE', language)} - {defaultTitle}
        </title>
        <meta
          name="description"
          content={translation('_CHARGING_DESCRIPTION', language)}
        />
        <meta property="og:title" content={defaultTitle} />
        <meta property="og:image" content="/public/productivity.svg" />
      </Helmet>

      <div className={styles.evbackground}>
        <div className={styles.evcontent}>
          <div className={styles.title}>
            <h1>{translation('_CHARGING_TITLE1', language)}</h1>
            <p>{translation('_CHARGING_TEXT1', language)}</p>
          </div>
        </div>
      </div>

      <Section className={styles.content}>
        <Link to="/expertise">{translation('_CHARGING_BACK', language)}</Link>
        <h1>{translation('_CHARGING_TITLE2', language)}</h1>
        <p>{translation('_CHARGING_TEXT2', language)}</p>

        <img
          src={chargeStation}
          className={styles.station}
          alt="EV Fast charge station"
        />

        <h1>{translation('_CHARGING_TITLE3', language)}</h1>
        <p>{translation('_CHARGING_TEXT3', language)}</p>

        <h3>{translation('_CHARGING_TITLE4', language)}</h3>
        <p>{translation('_CHARGING_TEXT4', language)}</p>

        <h3>{translation('_CHARGING_TITLE5', language)}</h3>
        <ul>
          <li>{translation('_CHARGING_TEXT51', language)}</li>
          <li>{translation('_CHARGING_TEXT52', language)}</li>
          <li>{translation('_CHARGING_TEXT53', language)}</li>
          <li>{translation('_CHARGING_TEXT54', language)}</li>
          <li>{translation('_CHARGING_TEXT55', language)}</li>
          <li>{translation('_CHARGING_TEXT56', language)}</li>
          <li>{translation('_CHARGING_TEXT57', language)}</li>
        </ul>

        <h3>{translation('_CHARGING_TITLE6', language)}</h3>
        <p>{translation('_CHARGING_TEXT6', language)}</p>
      </Section>
    </div>
  );
};

export default memo(EVCharging);
