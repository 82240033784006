const translate = {
  _SEO_TITLE1: 'Наймите мировых разработчиков для вашего бизнеса',
  _SEO_TITLE2:
    'Мы предоставляем услуги разработки на React.js, помогая бизнесу и стартапам создавать свои проекты мечты. Мы всегда ищем лучшие решения для бизнеса наших клиентов. Одним из таких решений является использование React.js для разработки онлайн-сервисов и веб-приложений.',
  _SEO_TITLE3:
    'Разработка приложений на React, разработка на Nodejs, приложения на React, серверный рендеринг, SSR-приложения, SSR-приложение на React',
  _HOME_LINK1: 'Экспертиза',
  _HOME_LINK2: 'Как мы работаем',
  _HOME_LINK3: 'AI решения',
  _HOME_CONTACT: `Связаться`,
  _HOME_SECTION3: 'Наш опыт',

  EXP_DESCRIPTION:
    'Профессиональная разработка на React — создание быстрых, адаптивных и масштабируемых веб-приложений. Современные технологии, высокий уровень UX/UI и оптимизация под ваши бизнес-цели.',
  EXT_T1: 'НАША ЭКСПЕРТИЗА',
  EXT_TT:
    'Наша команда может предоставить широкий спектр технологической экспертизы в мобильных, веб, настольных и корпоративных программных средах.',

  WANT_BUTTON: 'Хотите узнать больше?',
  EXP_TITLE1: 'Решения для электромобильности',
  EXP_TEXT1:
    'Эффективные решения для обеих сторон индустрии электромобильности - операторов EMSP и CPO. Гарантированный бесперебойный опыт благодаря внедрению открытых стандартов, разработке приложений и платформ, консалтингу в отрасли и многому другому.',

  EXP_TITLE2: 'Любые типы приложений на React',
  EXP_TEXT2:
    'Представьте, что вам нужно разработать веб-приложение с нуля для мониторинга и анализа сырых данных. Например, вы владеете средним интернет-магазином. Вас не устраивают существующие решения для аналитики данных, и вы хотите самостоятельно управлять компонентами вашей панели администратора. Также для вас очень важно работать с сырыми данными и создавать аналитические графики самостоятельно.',

  EXP_TITLE3: 'Потоки данных для проектов IoT',
  EXP_TEXT3: `Интернет вещей, или IoT, - это система взаимосвязанных вычислительных устройств, механических и цифровых машин, объектов, животных или людей, которые имеют уникальные идентификаторы (UID) и способность передавать данные через сеть без необходимости взаимодействия человека с человеком или человека с компьютером.`,
  EXP_TEXT4: `Объект в интернете вещей может быть человеком с имплантом монитора сердца, сельскохозяйственным животным с биочипом, автомобилем с встроенными датчиками, которые предупреждают водителя о низком давлении в шинах, или любым другим природным или искусственным объектом, которому может быть назначен IP-адрес и который способен передавать данные через сеть.`,
  EXP_TEXT5:
    'Все больше организаций в различных отраслях используют IoT для повышения эффективности работы, лучшего понимания клиентов для улучшения обслуживания, улучшения принятия решений и увеличения ценности бизнеса.',
  EXP_TITLE6: 'Видеостриминг',
  EXP_TEXT6:
    'Мы предлагаем широкий спектр услуг по разработке приложений для видеостриминга с большой командой специалистов, готовых предоставить результаты. В зависимости от ваших потребностей мы можем создать быстро работающие платформы для живого стриминга или услуги видео по запросу. Эти приложения не только удобны (как для пользователей, так и для администраторов), но и надежны и способны выдерживать большую нагрузку.',

  EXP_TITLE7: 'Планировщик маршрутов для многоточечной логистики',
  EXP_TEXT7: `Мы разрабатываем инновационные логистические решения, соответствующие уникальным потребностям коммерческого электротранспорта. Наша передовая система планирования маршрутов позволяет бизнесу оптимизировать маршруты, бесшовно интегрировать графики зарядки и повышать общую операционную эффективность автопарков электротранспорта.`,

  _HWORK1: 'Как мы работаем',
  _HWORK2:
    'Не существует идеи настолько сложной, чтобы нельзя было разработать оптимальное архитектурное решение. Наша команда опытных инженеров React привнесет в ваш проект богатый институциональный опыт работы с React, основанный на нашем реальном опыте создания различных приложений, от MVP до сложных корпоративных приложений.',
  _HWORK3: 'МЫ ПРЕДЛАГАЕМ',
  _HWORK31: 'самый современный стек технологий для Front-end и Backend',
  _HWORK32:
    'лучшие практики, основанные на нашем опыте работы с многими реальными производственными проектами',
  _HWORK34: 'готовые к производству и CI/CD решения',
  _HWORK35: 'квалифицированные архитекторы для React Native, React и Backend',
  _HWORK36: 'профессиональная обратная связь по текущему проекту',
  _HWORK37: 'поддержка реализации проекта от начала до конца',
  _HWORK4: 'Фокус на тестировании',
  _HWORK5:
    'Ключевая особенность Node.js - это высокое качество кода. Это означает, что он стабильный, предсказуемый и выполняет свою цель. Так как Node.js может быть как динамически типизированным, так и сильно типизированным языком, качество поддерживается на необходимом уровне благодаря автоматизированному тестированию. Экосистема Node.js предоставляет несколько современных фреймворков для автоматизированного тестирования. Они были отточены и проверены на практике на протяжении многих лет выдающимися разработчиками сообщества.',
  _HWORK6: 'Хотите узнать больше примеров?',

  _MOBILITY_DESCRIPTION:
    'Здесь вы можете найти наш раздел экспертизы в разработке программного обеспечения для электромобильности.',
  _MOBILITY_KEYWORDS:
    'решения для электромобильности на react, демо ocpp на react, система управления ocpp на react, панель управления ocpp, система биллинга ocpp, система управления зарядкой ocpp',
  _MOBILITY_HEAD_H1: 'Решения для электромобильности',
  _MOBILITY_HEAD_TEXT:
    'Эффективные решения для обеих сторон индустрии электромобильности - операторов EMSP и CPO. Гарантированный бесперебойный опыт благодаря внедрению открытых стандартов, разработке приложений и платформ, консалтингу в отрасли и многому другому.',
  _MOBILITY_BACK: 'Назад к разделу экспертизы',
  _MOBILITY_TITLE1: 'Продукты будущего для следующего поколения',
  _MOBILITY_TEXT1: `Через программное обеспечение мы предоставляем самые гибкие программные решения для инфраструктуры EV. Управляйте любым зарядным устройством OCPP, заряжайте любой электромобиль и принимайте все основные методы оплаты.`,
  _MOBILITY_TEXT2: `Наши инновационные продукты формируют будущее автомобильного дизайна. Мы базируем сложные автомобильные дизайны на перспективных решениях. Как давний и надежный партнер автомобильной индустрии, voestalpine производит инновационные продукты от дизайна до прототипа и от изготовления инструментов до серийного производства. Каждый компонент, будь то отдельная деталь или сложный модуль, соответствует необходимым требованиям безопасности и снижения веса. Таким образом, каждый инновационный компонент вносит вклад в светлое будущее.`,
  _MOBILITY_TITLE2: 'Давайте начнем',
  _MOBILITY_TEXT3:
    'Зарядка EV не должна быть сложной. Мы можем предоставить комплексное решение для инфраструктуры EV с множеством настроек и гибкой ценовой политикой для ваших клиентов.',
  _MOBILITY_TITLE4: 'Управление зарядными устройствами EV',
  _MOBILITY_TEXT4:
    'Подключайте и управляйте любым зарядным устройством OCPP. Вы можете создавать собственные программные планы, начиная с $0. Бесплатные часы, скидки на разные временные интервалы позволяют добавлять геймификацию и маркетинговые функции к вашим станциям (поддержка протоколов OCPP и известных производителей зарядных устройств для EV).',
  _MOBILITY_TITLE5: 'Интеграция управления EV с CRM',
  _MOBILITY_TEXT5:
    'Мы легко интегрируем наши системы управления зарядными устройствами в вашу систему CRM, сервисы уведомлений и реализуем интеграцию с любым сторонним ПО.',
  _MOBILITY_MORE_BUTTON: 'Подробнее о зарядке EV',
  _MOBILITY_TITLE6: 'Аккаунт водителя EV',
  _MOBILITY_TEXT6:
    'Никаких членских взносов или депозитов в кошельке не требуется, ни сейчас, ни в будущем.',

  _CHARGING_TITLE: 'Программное обеспечение для зарядки EV (OCPP)',
  _CHARGING_DESCRIPTION:
    'Максимизируйте прибыльность вашей сети зарядных станций и оцените наше передовое ПО для операторов и поставщиков услуг зарядных станций EV.',
  _CHARGING_TITLE1: 'Программное обеспечение для отрасли зарядки EV',
  _CHARGING_TEXT1:
    'Используйте наше программное обеспечение для зарядки EV с белой маркировкой для удобного управления инфраструктурой зарядки. Все зарядные станции администрируются через многоуровневый веб-портал с распределением ролей и ответственности — вы решаете, кто может получить доступ к системе. Мы предлагаем как аутсорсинговые, так и полностью самоуправляемые пакеты нашего программного обеспечения для управления зарядкой EV, соответствующие вашим бизнес-потребностям.',
  _CHARGING_TITLE2: 'Управление зарядкой стало проще',
  _CHARGING_TEXT2:
    'Программное обеспечение для управления зарядкой позволяет отслеживать, управлять и оптимизировать зарядку электромобилей и получать дополнительный доход. От доступа к расширенной аналитике через наш бизнес-портал до управления зарядкой в дороге через наше приложение Charge — переход на электромобили никогда не был таким простым.',
  _CHARGING_TITLE3: 'Оптимальное использование сети с EV Smart Charging',
  _CHARGING_TEXT3:
    'Если множество электромобилей заряжаются одновременно в одной сети, существует риск перегрузки энергосистемы. Благодаря Smart Charging вы можете увеличить количество электромобилей, которые могут заряжаться одновременно, без дополнительных инвестиций в сеть.',
  _CHARGING_TITLE4: 'Управление зарядными устройствами EV',
  _CHARGING_TEXT4:
    'Подключайте и управляйте любыми зарядными устройствами OCPP. Вы можете создавать собственные планы программного обеспечения от $0. Бесплатные часы, скидки в разное время позволяют добавлять геймификацию и маркетинговые функции к вашим станциям (поддержка протоколов OCPP и известных производителей зарядных устройств EV).',
  _CHARGING_TITLE5: 'Управление инфраструктурой',
  _CHARGING_TEXT51:
    'Управляйте зарядными станциями, устройствами и соединениями EV.',
  _CHARGING_TEXT52: 'Мониторьте сеансы, станции и зарядные устройства.',
  _CHARGING_TEXT53: 'Умное энергопотребление с балансировкой нагрузки.',
  _CHARGING_TEXT54: 'Решайте проблемы.',
  _CHARGING_TEXT55:
    'Выполняйте удаленные команды (перезагрузка, обновление прошивки и т.д.).',
  _CHARGING_TEXT56:
    'Панель приборов с данными в реальном времени и Google Maps.',
  _CHARGING_TEXT57:
    'Гибкость аппаратного обеспечения благодаря Open Charge Point Protocol — OCPP.',
  _CHARGING_TITLE6: 'Решения API-платформы',
  _CHARGING_TEXT6:
    'Настройте свой опыт зарядки EV с помощью нашей инновационной открытой платформы для зарядки и услуг. Наша API-платформа позволяет операторам создавать собственный пользовательский опыт через мощный API-шлюз, который позволяет разрабатывать собственное приложение для водителей и использовать портал управления для управления вашими станциями. Независимо от того, хотите ли вы интегрировать свое текущее мобильное приложение, привязать зарядку к вознаграждениям или членству, или воспользоваться другими услугами eMobility — наша API-платформа поддерживает ваши потребности. Она также идеально подходит для интеграции во флот, энергоменеджмент и телематику транспортных средств.',

  _DASHBOARD_TITLE: 'Разработка дашбордов (React)',
  _DASHBOARD_DESCRIPTION:
    'Максимизируйте прибыльность вашей сети зарядных станций и воспользуйтесь нашим передовым программным обеспечением для операторов и поставщиков услуг зарядных станций EV.',
  _DASHBOARD_TITLE1: 'Услуги разработки React',
  _DASHBOARD_TEXT1:
    'Хотите создать динамическое и интерактивное веб-приложение? Больше не ищите! Мы предоставляем услуги разработки ReactJS для удовлетворения всех ваших потребностей. ReactJS-приложения, которые мы создаем, обладают высокой функциональностью и имеют нативное ощущение для ваших мобильных приложений. Конечно, они легкие, быстрые и отзывчивые.',
  _DASHBOARD_BACK: 'Назад к разделу экспертизы',
  _DASHBOARD_TITLE2:
    'Решения для предприятий. Максимальная производительность. Качество кода.',
  _DASHBOARD_TEXT21:
    'У нас есть команда опытных React-разработчиков, которые используют гибкость и динамичность React JS для создания веб-приложений с высоким уровнем безопасности. Наши услуги помогают создавать веб-приложения с высокой скоростью, простотой и надежностью.',
  _DASHBOARD_TEXT22:
    'React JS является основной платформой для создания пользовательских интерфейсов. Все наши команды имеют большой опыт работы с React и успешно реализовали десятки проектов.',
  _DASHBOARD_TEXT23:
    'Доступ к опытным React.js разработчикам с историей успеха в разработке масштабируемых веб-приложений. Высокие стандарты качества, подтвержденные более чем 200 проектами.',
  _DASHBOARD_BUTTON: 'Показать примеры',
  _DASHBOARD_TITLE3: 'Начните завтра',
  _DASHBOARD_TEXT3:
    'Вам не нужно создавать внутреннюю организацию. Наймите нашу команду разработчиков для проекта и замените нас внутренним персоналом позже.',
  _DASHBOARD_TITLE4: 'Разработка React.js с нуля',
  _DASHBOARD_TEXT4:
    'Вы можете поручить нам разработку React, и мы создадим проект с нуля для вас.',
  _DASHBOARD_TITLE5: 'Миграция на React',
  _DASHBOARD_TEXT5:
    'Наши эксперты выполнят высококачественную миграцию на React с любой другой платформы.',
  _DASHBOARD_TITLE6: 'Команда разработчиков React.js',
  _DASHBOARD_TEXT6:
    'Наймите команду React.js-разработчиков, которые удовлетворят ваши потребности в масштабе. Они также могут переписать плохо написанный код другой компании для соответствия отраслевым стандартам.',
  _DASHBOARD_TITLE7: 'Разработка приложений React Native',
  _DASHBOARD_TEXT7:
    'Воспользуйтесь всеми преимуществами ведущей платформы для разработки кроссплатформенных мобильных приложений.',

  _AI_TITLE:
    'Веб-разработка нового поколения: Быстрые и качественные решения с Node.js, React.js и AI',
  _AI_TEXT:
    'В современном цифровом мире веб-разработка стала основой успешного бизнеса. Мы специализируемся на создании динамичных, масштабируемых и надежных веб-решений, которые обеспечивают высочайшую производительность и удобство. Наши инструменты — Node.js и React.js, а также технологии искусственного интеллекта (AI), которые позволяют создавать решения, полностью отвечающие вашим бизнес-потребностям.',
  _AI_KEYWORDS:
    'веб-разработка, создание лендингов, node.js разработка, react.js разработка, AI разработка, веб-приложения, создание веб-сайтов, разработка под ключ, модернизация веб-приложений, интеграция искусственного интеллекта, интерактивные веб-решения',
  _AI_TITLE1: 'Наш подход:',
  _AI_SECTION1: '1.	Быстрота и эффективность',
  _AI_TEXT1:
    'Мы используем Node.js для разработки серверной части, обеспечивая молниеносную обработку данных, и React.js — для создания интерактивных пользовательских интерфейсов. Это сочетание позволяет запускать проекты быстрее и с минимальными затратами.',
  _AI_SECTION2: '2.	Качество и надежность',
  _AI_TEXT2:
    'Мы стремимся к высочайшему качеству на всех этапах разработки. Наши специалисты внимательно следят за каждым шагом проекта, чтобы обеспечить его надежность и стабильность.',
  _AI_SECTION3: '3.	AI-интеграция',
  _AI_TEXT3:
    'Мы используем технологии искусственного интеллекта для создания умных и инновационных решений, которые помогают оптимизировать бизнес-процессы и повышают эффективность вашего бизнеса. Добавьте инновацию в ваш проект! Мы интегрируем возможности искусственного интеллекта, такие как:',
  _AI_TEXT31: 'Автоматизация процессов',
  _AI_TEXT32: 'Персонализация пользовательского опыта',
  _AI_TEXT33: 'Анализ больших данных для принятия решений',

  _AI_TITLE2: 'Наши услуги:',
  _AI_SECTION4: 'Создание веб-приложений с нуля',
  _AI_TEXT4:
    'Полный цикл разработки — от идеи до рабочего продукта. Мы разрабатываем мощные веб-приложения, которые эффективно решают задачи вашего бизнеса.',
  _AI_SECTION5: 'Миграция на современные платформы',
  _AI_TEXT5:
    'Перенос устаревших приложений на современные технологии Node.js и React.js для повышения производительности и улучшения интерфейса.',
  _AI_SECTION6: 'Интеграция AI в существующие системы',
  _AI_TEXT6:
    'Мы помогаем внедрить машинное обучение, чат-боты и другие AI-решения в ваш проект.',

  _AI_TITLE7: 'Готовы начать?',
  _AI_TEXT7:
    'Напишите нам сегодня, чтобы обсудить ваш проект и узнать, как мы можем помочь вашему бизнесу вырасти с помощью современных технологий Node.js, React.js и AI.'
};

export default translate;
